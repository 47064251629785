import request from '@/utils/request'
export default {
  // 列表
  userkeyList(data) {
    return request({
      url: '/userkey/list',
      method: 'post',
      data
    })
  },
  // 新增api
  userkeyAdd(data) {
    return request({
      url: '/userkey/add',
      method: 'post',
      data
    })
  },
  // 验证
  userkeyCaptcha(data) {
    return request({
      url: '/userkey/captcha',
      method: 'post',
      data
    })
  },
  // 删除
  userkeyDel(data) {
    return request({
      url: '/userkey/del',
      method: 'post',
      data
    })
  },
  // 删除所有
  userkeyDelall(data) {
    return request({
      url: '/userkey/delall',
      method: 'post',
      data
    })
  },
  // 编辑
  userkeyEdit(data) {
    return request({
      url: '/userkey/edit',
      method: 'post',
      data
    })
  }
}
