import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission'
import VueClipboard from 'vue-clipboard2'
import i18n from '@/i18n'

import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-variables.scss'
import 'element-ui/lib/theme-chalk/icon.css'
import '@/styles/index.scss' // global css
Vue.use(ElementUI)

// 导出组件
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import global_ from './utils/global' // 引用文件
Vue.prototype.GLOBAL = global_ // 挂载到Vue实例上面

// 挂在websocket
import ws from '@/utils/socket/index'
Vue.prototype.$ws = ws

// 事件总线
import { EventEmitter } from 'events'
Vue.prototype.$bus = new EventEmitter()

// 自定义指令
import Directive from '@/utils/directives/directives'
Vue.use(Directive)

// math.js
import { create, all } from 'mathjs'
const config = {
  epsilon: 1e-12,
  matrix: 'Matrix',
  number: 'BigNumber', // 可选值：number BigNumber ， BigNumber可以解决js小数点精度问题，而number不可行
  precision: 64,
  predictable: false,
  randomSeed: null
}
const math = create(all, config)
Vue.prototype.$math = math

// Decimal.js
import Decimal from 'decimal.js'
Decimal.set({
  precision: 20,
  rounding: Decimal.ROUND_UP
})
Vue.prototype.$decimal = Decimal

Vue.use(VueClipboard)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
