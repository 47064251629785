import request from '@/utils/request'
export default {
  // 获取帮助中心主页数据
  getArticleCenterApi() {
    return request({
      url: `/article/center`,
      method: 'get'
    })
  }
}
