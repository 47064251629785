import Home from '@/api/home'
import { handleRatio, getDate, getNum, thousandths } from '@/utils'
import global from '@/utils/global'

const handleData = result => {
  const tempData = result.type ? result.data : result
  const tempCompareList = {}
  if (tempData && tempData.length > 0) {
    const resultData = tempData.map((item, index) => {
      const tempObj = { ...item }
      tempObj.isHover = false
      tempObj.new_price = thousandths(getNum(item.new_price, item.price_digit))
      tempObj.ratio_str = handleRatio(item.ratio)
      tempObj.xData = []
      tempObj.sData = []
      tempObj.websocketCount = 0
      const kline = tempObj.Kline || null
      if (kline && kline.data && kline.data.length > 0) {
        kline.data.forEach(element => {
          const tempTime = getDate(Number(`${element[0]}`), 'YYYY-MM-DD')
          tempObj.xData.push(tempTime)
          tempObj.sData.push(element[4])
        })
      }
      const tradePair = `${item.coin}-${item.currency}`
      tempCompareList[tradePair] = index
      return tempObj
    })
    return { resultData, tempCompareList }
  }
  return { resultData: [], tempCompareList }
}

const handelTicker = data => {
  if (data && data.length > 0) {
    return data.map(item => {
      const temp = { ...item }
      temp.tradePair = `${item.coin}${item.currency}`
      temp.ratio_str = handleRatio(item.ratio)
      return temp
    })
  }
  return []
}

const handleBannerList = ({ data, params }) => {
  return data
}

const state = {
  banner_list: [],
  symbol_list: [],
  new_coin_list: [],
  ticker_list: [],
  compare_symbol: {},
  compare_new_symbol: {},
  pair_ticker_list: []
}

const mutations = {
  setBannerList(state, data) {
    state.banner_list = handleBannerList(data)
  },
  setSymbolListNew(state, { result, from }) {
    if (result && result.length > 0) {
      if (from === 'newCoin') {
        const { resultData, tempCompareList } = handleData({ data: result.slice(0, 3), type: from })
        state.new_coin_list = resultData
        state.compare_new_symbol = tempCompareList
      } else {
        const { resultData, tempCompareList } =
        from === 'light'
          ? handleData({ data: result.slice(0, 3), type: from })
          : handleData({ data: result.slice(0, 5), type: from })

        state.symbol_list = resultData
        state.compare_symbol = tempCompareList
      }
    }
  },
  updateSymbollist(state, data) {
    // const tempData = data.data
    if (state.new_coin_list.length > 0) {
      state.new_coin_list.forEach(item => {
        const tempPair = `${item.coin}-${item.currency}`
        let temp
        if (state.compare_new_symbol.hasOwnProperty(tempPair)) {
          temp = state.new_coin_list[state.compare_new_symbol[tempPair]]
          const tempData = data[tempPair]
          if (tempData) {
            // const oldP = Number(temp.new_price)
            // const newP = Number(tempData[3])
            // temp.color = newP > oldP ? global.basicSuccessColor : newP < oldP ? global.basicErrorColor : '#FFFFFF'
            temp.new_price = thousandths(getNum(tempData[3], temp.price_digit))
            temp.ratio_str = handleRatio(tempData[4])
            temp.color = temp.ratio_str.indexOf('-') > -1 ? global.basicErrorColor : global.basicSuccessColor
            temp.deal_num = thousandths(tempData[2])
            state.new_coin_list[state.compare_new_symbol[tempPair]] = temp
          }
        }
      })
    }
    if (state.symbol_list.length > 0) {
      state.symbol_list.forEach(item => {
        const tempPair = `${item.coin}-${item.currency}`
        let temp
        if (state.compare_symbol.hasOwnProperty(tempPair)) {
          temp = state.symbol_list[state.compare_symbol[tempPair]]
          const tempData = data[tempPair]
          if (tempData) {
            // const oldP = Number(temp.new_price)
            // const newP = Number(tempData[3])
            temp.new_price = thousandths(getNum(tempData[3], temp.price_digit))
            temp.ratio_str = handleRatio(tempData[4])
            temp.color = temp.ratio_str.indexOf('-') > -1 ? global.basicErrorColor : global.basicSuccessColor
            temp.deal_num = thousandths(tempData[2])
            temp.websocketCount += 1
            state.symbol_list[state.compare_symbol[tempPair]] = temp
          }
        }
      })
    }
  },
  setTickerList(state, data) {
    state.ticker_list = handelTicker(data)
  },
  resetTickerList(state) {
    state.ticker_list = []
  },
  resetSymbolList(state) {
    state.symbol_list = []
    state.compare_symbol = {}
  }
}

const actions = {
  async getBannerList({ commit }, params) {
    try {
      const res = await Home.getBannerListApi(params)
      commit('setBannerList', { data: res.data, params })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getSymbolListNew({ state, commit }, { type, from }) {
    try {
      state.symbol_list = []
      const res = await Home.getSymbolListNewApi(type)
      commit('setSymbolListNew', { result: res.data, from })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getTickerList({ commit }, data) {
    try {
      const res = await Home.searchTickerApi(data)
      commit('setTickerList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
