import request from '@/utils/request'
export default {
  // 合约持有仓位列表
  getFutPListApi(data) {
    return request({
      url: '/asset/getfutpositionslist',
      method: 'get',
      headers: { model_type: 'DAPI' },
      params: data
    })
  },
  // 获取订单列表
  getOrderListApi(params) {
    // params.type: 1-当前委托  2-历史委托  3-成交记录  4-资金流水
    const tempParams = { ...params }
    delete tempParams.type
    const url =
      params.type === 1
        ? '/trade/orders'
        : params.type === 2
          ? '/trade/orders'
          : params.type === 3
            ? '/trade/deals'
            : '/trade/capital/flow'

    const temp = {}
    temp.url = url
    temp.headers = { model_type: 'DAPI' }
    params.type == 4 ? temp.method = 'post' : temp.method = 'get'
    params.type == 4 ? temp.data = tempParams : temp.params = tempParams
    return request(temp)
  },
  // 获取订单详情
  getOrderInfoApi(params) {
    return request({
      url: '/trade/order',
      method: 'get',
      headers: { model_type: 'DAPI' },
      params
    })
  },
  // 撤单
  cancelOrderApi(data) {
    return request({
      url: '/trade/cancelorder',
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 批量撤单
  cancelbatchordersApi() {
    return request({
      url: '/trade/cancelbatchorders',
      method: 'post',
      headers: { model_type: 'DAPI' }
    })
  },
  // 一键平仓
  closeAllApi() {
    return request({
      url: '/trade/closeall',
      method: 'post',
      headers: { model_type: 'DAPI' }
    })
  },
  // 调整逐仓保证金
  adjustMarginApi(data) {
    console.log(data)
    return request({
      url: '/trade/adjustmargin',
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 逐仓调整保证金计算强平价
  calcPriceApi(data) {
    return request({
      url: '/calc/price',
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 持仓分享
  shareApi(params) {
    return request({
      url: '/fut/share/list',
      method: 'get',
      params
    })
  }
}
