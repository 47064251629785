import axios from 'axios'
import { Message, Notification } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { getUserAgentInfo } from '@/utils'
// import qs from 'qs'
import router from '@/router'

import { host, otc_baseUrl, dapi_baseUrl } from './hostConfig'
let isShowNotify = false
// create an axios instance
const service = axios.create({
  // baseURL: 'http://192.168.3.110:6226/api', // otc开发服
  baseURL: host,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 150000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // OTC 请求
    if (config.headers && config.headers.model_type === 'OTC') {
      config.baseURL = otc_baseUrl
    }
    // 币本位 请求
    if (config.headers && config.headers.model_type === 'DAPI') {
      config.baseURL = dapi_baseUrl
    }

    if (store.getters.token) {
      config.headers.token = getToken()
    }
    const isForm = config.content_type === 'form'
    config.headers['content-type'] = isForm
      ? 'application/x-www-form-urlencoded;charset=UTF-8'
      : 'application/json'
    config.headers['os'] = 3
    config.headers['device'] = getUserAgentInfo()
    // config.headers. = 'zh-CN'
    if (!config.data) config.data = {}
    // config.data = isForm ? qs.stringify(config.data) : config.data;
    return config
  },
  (error) => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

function handleCommonResponse(response) {
  const res = response.data
  if ([-1].includes(res.status)) {
    // 未登录
    store.dispatch('user/logout').then(() => {
      router.push({ path: '/login' })
    })
    return Promise.reject(res.message)
  }
  if ([-2].includes(res.status)) {
    // 账号被踢
    if (!isShowNotify) {
      isShowNotify = true
      Notification({
        title: '登录提醒',
        type: 'warning',
        message: '您的账户在其他设备登录，如果这不是您的操作，请及时修改您的登录密码。',
        duration: 0,
        customClass: 'login_warn_notify',
        onClose: () => {
          store.dispatch('user/logout').then(() => {
            location.reload()
          })
        }
      })
    }
    return Promise.reject()
  }
  if ([50004, 10028].includes(res.code)) {
    // 特殊处理状态
    return res
  }
  const configData = JSON.parse(response.config.data)
  if (configData.retrieve_type === 1 && res.code === 10012) {
    // 10012: 账号未注册
    return res
  }
  Message({
    message: res.msg || 'Error',
    type: 'error',
    duration: 2 * 1000
  })
  return Promise.reject(res.message || 'Error')
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // console.log('response', response)
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    // res.status: -1：未登录  -2：被挤掉线  0:请求异常  1：请求正常
    /**
     * 70000: kyc未认证
     * 70001: 绑定手机
     * 70003: 法币订单取消今天已达到3次
     * 70004: 当前所选法币不在用户KYC所在地区的法币清单内
     * 70005: 用户KYC地区在C2C限制交易地区
     * 70006: 当前有正在进行中的订单，不能再下单
     * 70008: 您的账户目前被限制进行法币交易，请联系客服
     * 70016: 您24小时内修改了验证方式，24小时内不可进行出售
     * 70018：不在该交易币的限额范围内
     * 70043: 当前有正在进行的订单，无法修改
     * 70055: 当前有未下架的广告，不能解除认证
     */

    // 指定的url路由下code通过
    const codeList = [70000, 70001, 70003, 70004, 70005, 70006, 70016, 70018, 70019, 70043, 70055]
    const routerUrl = response.config.url
    const whiteUrlList = [
      '/trade/quickbuy',
      '/trade/quicksell',
      '/trade/buyorder',
      '/trade/sellorder',
      '/trade/checkbuy',
      '/trade/checksell',
      '/c2c/updateadvertiseshow',
      '/c2c/validmerchant'
    ]
    if (whiteUrlList.includes(routerUrl)) {
      if (res.status !== 1 && !codeList.includes(res.code)) {
        return handleCommonResponse(response)
      }
    } else {
      if (res.status != 1) {
        return handleCommonResponse(response)
      }
    }
    return res
  },
  (error) => {
    console.log(`err${error}`) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
