import transfer from '@/api/transfer'

const state = {}
const mutations = {}

const actions = {
  // 获取列表
  async insideTransList() {
    try {
      const res = await transfer.insideTransList()
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  //   新增
  async insideTransAdd(no, data) {
    try {
      const res = await transfer.insideTransAdd(data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 编辑备注
  async insideTransEdit(no, data) {
    try {
      const res = await transfer.insideTransEdit(data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 删除
  async insideTransDel(no, data) {
    try {
      const res = await transfer.insideTransDel(data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  //   内部转账验证接口
  async withdraTransfervalid(no, data) {
    try {
      const res = transfer.withdraTransfervalid(data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  //   转账提交
  async withdrawTransfer(no, data) {
    try {
      const res = transfer.withdrawTransfer(data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  //   转账提交
  async userUpdatetransferopen(no, data) {
    try {
      const res = transfer.userUpdatetransferopen(data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 转账额度验证
  async withdrawTransferquota(no, data) {
    try {
      const res = transfer.withdrawTransferquota(data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
