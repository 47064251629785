// if (tempData.e === 'depth') {
//   // 买卖5盘推送
//   tempObj.depthBid = tempData.bid
//   tempObj.depthAsk = tempData.ask
// }
// if (tempData.e === 'trade') {
//   // 最新成交
//   tempObj.newDeal = [tempData.data.p, tempData.data.q, tempData.data.t, tempData.data.m]
// }
// if (tempData.e === 'trade') {
//   // k线推送
//   tempObj.klineData = [tempData.data.t, tempData.data.o, tempData.data.h, tempData.data.l, tempData.data.c, tempData.data.v, tempData.data.i, tempData.data.q]
// }

const getDefaultState = () => {
  return {
    webSocketDataList: {
      depthBid: [],
      depthAsk: [],
      newDeal: [],
      klineData: [],
      quotationList: [],
      allMarketList: {},
      allQuotesList: [],
      spotQuotesList: {},
      futQuotesList: {},
      deliveryQuotesList: {},
      deliveryMarketList: {}
    }
  }
}
const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setMarketWebsocketData(state, data) {
    state.webSocketDataList.allMarketList = {
      symbol: data.s,
      data: [data.data.p, data.data.r, data.data.i, data.data.T]
    }
  },
  setDeliveryMarketWebsocketData(state, data) {
    state.webSocketDataList.deliveryMarketList = {
      symbol: data.s,
      data: [data.data.p, data.data.r, data.data.i, data.data.T]
    }
  },
  setKlineWebsocketData(state, data) {
    state.webSocketDataList.klineData = [
      data.s,
      Number(data.data.t) * 1000,
      data.data.o,
      data.data.h,
      data.data.l,
      data.data.c,
      data.data.v,
      data.data.i,
      data.data.q
    ]
  },
  setNewTradeWebsocketData(state, data) {
    if (data.data && data.data.length > 0) {
      state.webSocketDataList.newDeal = data.data.map((item) => {
        return [item.p, item.q, item.t, item.m]
      })
    }
  },
  setDepthWebsocketData(state, data) {
    state.webSocketDataList.depthBid = data.bid
    state.webSocketDataList.depthAsk = data.ask
  },
  setTickerWebsocketData(state, data) {
    const item = data.data
    state.webSocketDataList.quotationList = [
      item.v,
      item.c,
      item.l,
      item.h,
      item.o,
      item.chg,
      item.q,
      item.vc
    ]
  },
  setAllTickerWebsocketData(state, data) {
    state.webSocketDataList.allQuotesList = []
    if (data.data && data.data.length > 0) {
      state.webSocketDataList.allQuotesList = data.data.map((item) => {
        return [item.s, item.v, item.c, item.l, item.h, item.o, item.chg, item.q]
      })
    }
  },
  setSpotTickerWebsocketData(state, data) {
    if (data.data && data.data.length > 0) {
      data.data.forEach((item) => {
        state.webSocketDataList.spotQuotesList[item.s] = [
          item.s,
          item.s,
          item.v,
          item.c,
          item.chg,
          item.q,
          item.l,
          item.h,
          item.rp
        ]
      })
    }
  },
  setFutTickerWebsocketData(state, data) {
    if (data.data && data.data.length > 0) {
      data.data.forEach((item) => {
        state.webSocketDataList.futQuotesList[item.s] = [item.q, item.c, item.l, item.h, item.chg]
      })
    }
  },
  setDeliveryTickerWebsocketData(state, data) {
    if (data.data && data.data.length > 0) {
      data.data.forEach((item) => {
        state.webSocketDataList.deliveryQuotesList[item.s] = [
          item.q,
          item.c,
          item.l,
          item.h,
          item.chg
        ]
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
