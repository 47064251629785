import request from '@/utils/request'
export default {
  // 获取分类列表
  getCategoryListApi() {
    return request({
      url: '/message/category/list',
      method: 'get'
    })
  },

  // 获取信息列表
  getMessageListApi(data) {
    return request({
      url: '/message/list',
      method: 'post',
      data
    })
  },
  // 获取信息详情
  getMessageDetailApi(data) {
    return request({
      url: `/message/details`,
      method: 'post',
      data
    })
  },
  // 设置已读
  setAllReadApi() {
    return request({
      url: '/message/allread',
      method: 'post'
    })
  },
  // 获取未读数量
  getUnreadCountApi() {
    return request({
      url: '/message/unread',
      method: 'get'
    })
  }
}
