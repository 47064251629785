import Bussiness from '@/api/bussiness'
import BuyCoins from '@/api/buyCoins'
const state = {}

const mutations = {}

const actions = {
  // 付款方式
  async getSelectPayamount({ commit }, data) {
    try {
      const res = await Bussiness.getSelectPayamount(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家广告
  async getBusinessAd({ commit }, data) {
    try {
      const res = await Bussiness.getBusinessAd(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家发布购买/出售 广告
  async doAdAdd({ commit }, data) {
    try {
      // type 1 购买广告 2出售广告
      let res = {}
      if (data.type === 1) {
        res = await Bussiness.buyAdvertieseAdd(data.params)
      } else {
        res = await Bussiness.saleAdvertiseAdd(data.params)
      }
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家发布广告需要的数据
  async getAdAddData() {
    try {
      const legalRes = await BuyCoins.getLegalcoins()
      const coinRes = await BuyCoins.getCoinsType(3)
      return { legalidCoinList: legalRes.data, coinList: coinRes.data }
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家广告获取付款方式
  async getAdPayAmount({ commit }, data) {
    try {
      const res = await Bussiness.selectPayAmount(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家广告收款方式
  async selectRecpayAccount({ commit }, data) {
    try {
      const res = await Bussiness.selectRecpayAccount(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家广告广告详情
  async getAdvertiseDetail({ commit }, data) {
    try {
      const res = await Bussiness.getAdvertiseDetail(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家广告成交记录
  async getOrdersByAdvertiseId({ commit }, data) {
    try {
      const res = await Bussiness.getOrdersByAdvertiseId(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 广告修改前验证
  async advertiSeeditBefore({ commit }, data) {
    try {
      const res = await Bussiness.advertiSeeditBefore(data)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 购买/出售广告编辑提交
  async buyOrSaleAdvertiSeedit({ commit }, data) {
    try {
      // type 1 编辑购买广告 2编辑出售广告
      let res = {}
      if (data.type === 1) {
        res = await Bussiness.buyAdvertiSeedit(data.params)
      } else {
        res = await Bussiness.saleAdvertiSeedit(data.params)
      }
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 设置显示或隐藏广告
  async updateAdvertiseShow({ commit }, data) {
    try {
      const res = await Bussiness.updateAdvertiseShow(data)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 下架广告
  async updateAdvertiseStatus({ commit }, data) {
    try {
      const res = await Bussiness.updateAdvertiseStatus(data)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家主页
  async getMerchantInfo({ commit }, data) {
    try {
      const res = await Bussiness.getMerchantInfo(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家主页广告列表
  async getMerchantAdveriseList({ commit }, data) {
    try {
      const resBuy = await Bussiness.getMerchantAdveriseList({
        merchantid: data,
        advertisetype: 1
      })
      const resSell = await Bussiness.getMerchantAdveriseList({
        merchantid: data,
        advertisetype: 3
      })
      return { buyList: resBuy.data, sellList: resSell.data }
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家解除认证前验证
  async validMerchant({ commit }, data) {
    try {
      const res = await Bussiness.validMerchant(data)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家解除认证
  async unauthMerchant({ commit }, data) {
    try {
      const res = await Bussiness.unauthMerchant(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 更新商家昵称
  async unpdateMerchantNick({ commit }, data) {
    try {
      const res = await Bussiness.unpdateMerchantNick(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 商家补充保证金
  async updateMerchantBalance({ commit }, data) {
    try {
      const res = await Bussiness.updateMerchantBalance(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 申请成为商家
  async merchantApply({ commit }, data) {
    try {
      const res = await Bussiness.merchantApply(data)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 聊天列表
  async getChatList({ commit }) {
    try {
      const res = await Bussiness.getChatList()
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
