import Invite from '@/api/invite'

const state = {
  inviteCodeList: [],
  statistics: {},
  commissionRecord: {},
  cashRecord: {},
  inviteUserList: {},
  inviteUser: {}
}

const mutations = {
  getInviteCodeList(state, data) {
    state.inviteCodeList = data
  },
  getStatistics(state, data) {
    state.statistics = data
  },
  getCommission(state, data) {
    state.commissionRecord = data
  },
  getCash(state, data) {
    state.cashRecord = data
  },
  getInviteUserList(state, data) {
    state.inviteUserList = data
  },
  getInviteUser(state, data) {
    state.inviteUser = data
  }
}

const actions = {
  async getInviteCodeList({ commit }) {
    try {
      const res = await Invite.getInviteCodeListApi()
      commit('getInviteCodeList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getStatistics({ commit }) {
    try {
      const res = await Invite.getStatisticsApi()
      commit('getStatistics', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCommission({ commit }, data) {
    try {
      const res = await Invite.getCommissionApi(data)
      if (data.CommisType === 1) {
        commit('getCash', res.data)
      } else {
        commit('getCommission', res.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getInviteUserList({ commit }, data) {
    try {
      const res = await Invite.getInviteUserListApi(data)
      if (!data.Rate) {
        commit('getInviteUserList', res.data)
      } else {
        commit('getInviteUser', res.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async modifyCodeMark({ commit }, data) {
    try {
      await Invite.modifyCodeMarkApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async addInviteCode({ commit }, data) {
    try {
      await Invite.addInviteCodeApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async homeGetinviteimage(no, data) {
    try {
      const res = await Invite.homeGetinviteimage(data)
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
