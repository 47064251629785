import request from '@/utils/request'
export default {
  // 获取资产总览
  getAssetsTotalApi() {
    return request({
      url: '/account/sumhold',
      method: 'get'
    })
  },
  // 获取现货总览
  getSpotcointotalApi() {
    return request({
      url: '/account/spotcointotal',
      method: 'get'
    })
  },
  // 获取累计收益率
  getHoldlogListApi(data) {
    return request({
      url: `/account/getholdloglist?begin_time=${data.start}&end_time=${data.end}`,
      method: 'get'
    })
  },
  // 获取币种资产
  getCoinAssetApi(params) {
    return request({
      url: '/account/getcoinasset',
      method: 'get',
      params
    })
  },
  // 获取资产流水记录
  getRecordListApi(params) {
    /*
      params.type: 1 -- 充值  2 -- 提币  3 -- 划转  4 -- 现货交易  5 -- 返佣  6 -- 转账
    */
    const url =
      params.tabType === 1
        ? `/recharge/list`
        : params.tabType === 2
          ? `/withdraw/list`
          : params.tabType === 3
            ? '/transfer/list'
            : params.tabType === 4
              ? '/trade/GetHistoryOrders'
              : params.tabType === 5
                ? ''
                : 'inside/trans/hold/log'
    const paramsData = { ...params }
    delete paramsData.tabType
    const requestQarams = {}
    requestQarams.url = url
    if (params.tabType == 4) {
      requestQarams.method = 'get'
      requestQarams.params = paramsData
    } else {
      requestQarams.method = 'post'
      requestQarams.data = paramsData
    }

    return request(requestQarams)
  },
  // 获取所有币种信息
  getCoinListApi() {
    return request({
      url: `/market/allcoin`,
      method: 'get'
    })
  },
  // 获取充值 || 提现网络列表
  getDepositListApi(data) {
    const url = data.type === 1 ? `/recharge/network/list?coin=${data.coin}` : `/withdraw/network/list?coin=${data.coin}`
    return request({
      url,
      method: 'get'
    })
  },
  // 获取充值地址
  getDepositAddressApi(data) {
    return request({
      url: `/recharge/deposit/address`,
      method: 'post',
      data
    })
  },
  // 获取记录详情
  getRecordInfoApi(params) {
    const url = params.type === 1 ? '/recharge/info' : '/withdraw/info'
    const data = { ...params }
    delete data.type
    return request({
      url,
      method: 'get',
      params: data
    })
  },
  // 获取提现额度等相关信息
  getWithdrawInfoApi(data) {
    return request({
      url: '/withdraw/quota',
      method: 'post',
      data
    })
  },
  // 提现验证三要素
  withdrawCaptchaApi(data) {
    return request({
      url: '/withdraw/captcha',
      method: 'post',
      data
    })
  },
  // 提现
  withdrawApi(data) {
    return request({
      url: '/withdraw/add',
      method: 'post',
      data
    })
  },
  // 获取资金总览
  getAssetstotalApi() {
    return request({
      url: '/c2c/getotccointotal',
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },
  // 获取资金币种列表
  getAssetsCoinListApi() {
    return request({
      url: '/c2c/getcoinasset',
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },
  // 获取otc资产统计
  getOtcAssetsApi() {
    return request({
      url: '/account/getotcasset',
      method: 'get'
    })
  },
  // 获取币种资产记录
  getCoinRecordApi(params) {
    return request({
      url: '/account/coin/holdlog',
      method: 'get',
      params
    })
  },
  // otc 资金流水
  otcAssetsRecordApi(data) {
    return request({
      url: `/c2c/getholdlog`,
      method: 'get',
      params: data,
      headers: { model_type: 'OTC' }
    })
  }
}
