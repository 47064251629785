import request from '@/utils/request'

export default {
  // 获取国家列表
  getCountryApi() {
    return request({
      url: '/country/list',
      method: 'get'
    })
  },
  // 登录
  loginApi(data) {
    return request({
      url: '/user/loginPwd',
      method: 'post',
      data
    })
  },
  // 获取二维码秘钥
  getLoginQRCodeApi() {
    return request({
      url: '/scanLogin/secret',
      method: 'get'
    })
  },
  // 检测当前登录状态
  checkUserLoginApi(data) {
    return request({
      url: `/scanLogin/status?secret=${data}`,
      method: 'get'
    })
  },
  // 注册
  registerApi(data) {
    return request({
      url: '/user/register',
      method: 'post',
      data
    })
  },
  // 忘记密码
  forgetApi(data) {
    return request({
      url: '/user/resetPwd',
      method: 'post',
      data
    })
  },
  // 发送验证码
  sendCodeApi(data) {
    return request({
      url: '/auth/send/captcha',
      method: 'post',
      data
    })
  },
  // 校验验证码
  checkCodeApi(data) {
    return request({
      url: '/user/resetPwd/check',
      method: 'post',
      data
    })
  },
  // 获取用户信息
  getUserInfoApi() {
    return request({
      url: '/user/info',
      method: 'get'
    })
  },
  // 更新用户信息
  updateUserInfoApi(data) {
    return request({
      url: '/user/update',
      method: 'post',
      data
    })
  },
  // 获取头像列表
  getAvatarApi() {
    return request({
      url: '/user/avatar/list',
      method: 'get'
    })
  },
  // 修改密码
  editPasswordApi(data) {
    return request({
      url: '/user/changePwd',
      method: 'post',
      data
    })
  },
  // 手机 || 邮箱 || 谷歌验证码 绑定、更改和关闭
  operateAuthApi(data) {
    return request({
      url: '/user/auth/switch',
      method: 'post',
      data
    })
  },
  getGoogleSecretApi(data) {
    return request({
      url: `/user/google/secret?type=${data}`,
      method: 'get'
    })
  },
  // 设置用户汇率
  setRateCountryApi(data) {
    return request({
      url: `/user/ratecountry`,
      method: 'post',
      data
    })
  },
  // 获取用户设置汇率
  getRateCountryApi() {
    return request({
      url: `/user/ratecountry`,
      method: 'get'
    })
  },
  // 获取汇率列表
  getRateApi() {
    return request({
      url: `/market/rate`,
      method: 'get'
    })
  }
}
