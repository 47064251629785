import Order from '@/api/spot/order'
import { getDate, getNum } from '@/utils'
import * as math from 'mathjs'

const handleTableList = data => {
  if (data && data.length > 0) {
    return data.map(item => {
      const temp = { ...item }
      temp.create_time = item.create_time ? getDate(Number(`${item.create_time.toString()}`), 'YYYY-MM-DD HH:mm:ss') : ''
      temp.update_time = item.update_time ? getDate(Number(`${item.update_time.toString()}`), 'YYYY-MM-DD HH:mm:ss') : ''
      temp.orderNum = getNum(math.add(Number(item.deal_num), Number(item.num)), 5, false)
      if (temp.poundage) {
        temp.poundage = getNum(temp.poundage, 8)
      }
      return temp
    })
  }
}

const handleOrderInfo = data => {
  if (Object.keys(data).length > 0) {
    const temp = { ...data }
    temp.create_time = data.create_time ? getDate(Number(`${data.create_time.toString()}`), 'YYYY-MM-DD HH:mm:ss') : ''
    temp.update_time = data.update_time ? getDate(Number(`${data.update_time.toString()}`), 'YYYY-MM-DD HH:mm:ss') : ''
    if (temp.deal_list && temp.deal_list.length > 0) {
      temp.deal_list = temp.deal_list.map(item => {
        const tempItem = { ...item }
        tempItem.create_date = getDate(Number(`${item.create_date.toString()}`), 'YYYY-MM-DD HH:mm:ss')
        tempItem.amount = getNum(math.multiply(item.num, item.price), 8)
        tempItem.currency = data.currency
        const poundageArr = item.poundage.split(' ')
        tempItem.poundage = `${getNum(poundageArr[0], 8)} ${poundageArr[1]}`
        return tempItem
      })
    }
    return temp
  }
}

const state = {
  entrust_list: {
    list: [],
    total: 0
  },
  history_entrust_list: {
    list: [],
    total: 0
  },
  history_list: {
    list: [],
    total: 0
  },
  order_info: {}
}

const mutations = {
  resetEntrustList(state) {
    state.entrust_list = {
      list: [],
      total: 0
    }
  },
  setEntrustList(state, { type, data }) {
    if (type == 0) {
      state.entrust_list.list = handleTableList(data.data_list)
      state.entrust_list.total = data.data_total
    } else {
      state.history_entrust_list.list = handleTableList(data.data_list)
      state.history_entrust_list.total = data.data_total
    }
  },
  setHistoryList(state, data) {
    state.history_list.list = handleTableList(data.data_list)
    state.history_list.total = data.data_total
  },
  setOrderInfo(state, data) {
    state.order_info = handleOrderInfo(data)
  }
}

const actions = {
  async getEntrustList({ commit }, data) {
    try {
      const res = await Order.getEntrustListApi(data)
      commit('setEntrustList', { type: data.get_type, data: res.data })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getHistoryList({ commit }, data) {
    try {
      const res = await Order.getHistoryListApi(data)
      commit('setHistoryList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getOrderInfo({ commit }, orderNo) {
    try {
      const res = await Order.getOrderInfoApi(orderNo)
      commit('setOrderInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async cancelOrder(no, data) {
    try {
      await Order.cancelOrderApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
