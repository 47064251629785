import request from '@/utils/request'

// 商家后台
export default {
  // 获取付款方式
  getSelectPayamount(data) {
    return request({
      url: '/c2c/selectpayamount',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 当前广告，历史广告
  getBusinessAd(data) {
    return request({
      url: '/c2c/getadvertisebystatus',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家发布购买广告
  buyAdvertieseAdd(data) {
    return request({
      url: '/c2c/buyadvertieseadd',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家发布出售广告
  saleAdvertiseAdd(data) {
    return request({
      url: '/c2c/saleadvertiseadd',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家广告获取付款方式
  selectPayAmount(data) {
    return request({
      url: '/c2c/selectpayamount',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家广告收款方式  出售
  selectRecpayAccount(data) {
    return request({
      url: '/c2c/selectrecpayaccount',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家广告广告详情
  getAdvertiseDetail(id) {
    return request({
      url: '/c2c/getadvertisedetail/' + id,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 商家广告成交记录
  getOrdersByAdvertiseId(id) {
    return request({
      url: '/c2c/getordersbyadvertiseid/' + id,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 广告修改前验证
  advertiSeeditBefore(id) {
    return request({
      url: '/c2c/advertiseeditbefore/' + id,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 购买广告编辑提交
  buyAdvertiSeedit(data) {
    return request({
      url: '/c2c/buyadvertiseedit',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 出售广告编辑提交
  saleAdvertiSeedit(data) {
    return request({
      url: '/c2c/saleadvertiseedit',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 设置显示或隐藏广告
  updateAdvertiseShow(data) {
    return request({
      url: '/c2c/updateadvertiseshow',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 下架广告
  updateAdvertiseStatus(data) {
    return request({
      url: '/c2c/updateadvertisestatus',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家主页
  getMerchantInfo(data) {
    return request({
      url: '/c2c/getmerchantinfo',
      method: 'get',
      params: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家主页广告列表
  getMerchantAdveriseList(data) {
    return request({
      url: '/c2c/getmerchantadveriselist',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家解除认证前验证
  validMerchant(data) {
    return request({
      url: '/c2c/validmerchant',
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 商家解除认证
  unauthMerchant(data) {
    return request({
      url: '/c2c/unauthmerchant',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 更新商家昵称
  unpdateMerchantNick(data) {
    return request({
      url: '/c2c/updatemerchantnick',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 商家补充保证金
  updateMerchantBalance(data) {
    return request({
      url: '/c2c/updatemerchantbalance',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 申请成为商家
  merchantApply(data) {
    return request({
      url: '/c2c/merchantapply',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 聊天列表
  getChatList() {
    return request({
      url: '/trade/chat/list',
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  }
}

// 文件上传， 可单独导出
export function uploadFile(data) {
  return request({
    url: '/upload/file',
    method: 'post',
    data,
    content_type: 'form',
    headers: { model_type: 'OTC' }
  })
}
