import BuyOrder from '@/api/buyOrder'
const state = {}
const mutations = {}
const actions = {
  // 订单详情
  async getOrderDetail({ commit }, data) {
    try {
      const res = await BuyOrder.getOrderDetail(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 确认支付
  async buyerPayOrder({ commit }, data) {
    try {
      const res = await BuyOrder.buyerPayOrder(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 确认放币
  async sellerOrderRelease({ commit }, data) {
    try {
      const res = await BuyOrder.sellerOrderRelease(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 手动划扣
  async manualScoring({ commit }, data) {
    try {
      const res = await BuyOrder.manualScoring(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 取消订单
  async buyerCancelOrder({ commit }, data) {
    try {
      const res = await BuyOrder.buyerCancelOrder(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 买家/卖家提交申诉
  async buyerOrSellerAppealSubmit({ commit }, data) {
    try {
      let res = {}
      if (data.type === 1) {
        res = await BuyOrder.buyerAppealSubmit(data.params)
      } else {
        res = await BuyOrder.sellerAppealSubmit(data.params)
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 申诉方/被诉方提交补充说明
  async defendantOrAppealSupplement({ commit }, data) {
    try {
      let res = {}
      if (data.type === 1) {
        res = await BuyOrder.appealSupplement(data.params)
      } else {
        res = await BuyOrder.defendantSupplement(data.params)
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 申诉方取消申诉
  async cancelAppeal({ commit }, data) {
    try {
      const res = await BuyOrder.cancelAppeal(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 申诉方/被诉方确认无法协商
  async defendantOrAppealUnConsensus({ commit }, data) {
    try {
      let res = {}
      if (data.type === 1) {
        res = await BuyOrder.appealUnConsensus(data.params)
      } else {
        res = await BuyOrder.defendantUnConsensus(data.params)
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 申诉方/被诉方确认已协商解决
  async defendantOrAppealConsensus({ commit }, data) {
    try {
      let res = {}
      if (data.type === 1) {
        res = await BuyOrder.appealConsensus(data.params)
      } else {
        res = await BuyOrder.defendantConsensus(data.params)
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 查看申诉进度
  async getAppealProgress({ commit }, data) {
    try {
      const res = await BuyOrder.getAppealProgress(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 聊天记录
  async getChatLog({ commit }, data) {
    try {
      const res = await BuyOrder.getChatLog(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  //  给买家发送收款账号
  async sendPayAccountToBuyer({ commit }, data) {
    try {
      const res = await BuyOrder.sendPayAccountToBuyer(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
