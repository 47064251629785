import request from '@/utils/request'

export default {
  // 订单详情
  getOrderDetail(id) {
    return request({
      url: `/trade/orderdetail/${id}`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 确认支付
  buyerPayOrder(data) {
    return request({
      url: `/trade/buyerpayorder`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 确认放币
  sellerOrderRelease(data) {
    return request({
      url: `/trade/sellerorderrelease`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 手动划扣
  manualScoring(data) {
    return request({
      url: `/trade/sellerorderbuckle`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 取消订单
  buyerCancelOrder(data) {
    return request({
      url: `/trade/buyercancelorder`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 买家提交申诉
  buyerAppealSubmit(data) {
    return request({
      url: `/trade/buyerappealsubmit`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 卖家提交申诉
  sellerAppealSubmit(data) {
    return request({
      url: `/trade/sellerappealsubmit`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 申诉方提交补充说明
  appealSupplement(data) {
    return request({
      url: `/trade/appealsupplement`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 被诉方提交补充说明
  defendantSupplement(data) {
    return request({
      url: `/trade/defendantsupplement`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 申诉方取消申诉
  cancelAppeal(data) {
    return request({
      url: `/trade/cancelappeal`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 申诉方确认无法协商
  appealUnConsensus(data) {
    return request({
      url: `/trade/appealunconsensus`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 被诉方确认无法协商
  defendantUnConsensus(data) {
    return request({
      url: `/trade/defendantunconsensus`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 申诉方确认已协商解决
  appealConsensus(data) {
    return request({
      url: `/trade/appealconsensus`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 被诉方确认已协商解决
  defendantConsensus(data) {
    return request({
      url: `/trade/defendantconsensus`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 查看申诉进度
  getAppealProgress(data) {
    return request({
      url: `/trade/getappealprogress/${data}`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 聊天记录
  getChatLog(data) {
    return request({
      url: `/trade/chat/log`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 给买家发送收款账号
  sendPayAccountToBuyer(data) {
    return request({
      url: `/trade/sendpayaccounttobuyer`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  }
}

// 文件上传， 可单独导出
export function uploadFile(data) {
  return request({
    url: '/upload/file',
    method: 'post',
    data,
    content_type: 'form',
    headers: { model_type: 'OTC' }
  })
}
