
// 后端api前缀
const hosts = {
  development: 'http://47.243.128.29:6236/api',
  // development: 'http://192.168.3.110:6226/api',
  production: '/api'
}

// 币本位请求
const dapi_Url = {
  development: 'http://47.243.128.29:6236/dapi/v1',
  // development: 'http://192.168.3.110:6226/api',
  production: '/dapi/v1'
}
// otc请求
const otc_url = {
  development: 'http://47.243.128.29:6247/api',
  // development: 'http://192.168.3.159:6247/api', // ycz本地
  // development: 'http://192.168.3.41:6247/api', // qinghua
  // development: 'http://192.168.3.110:6247/api',
  production: '/sapi'
}

// 定义变量(是否是测试环境还是测试其他环境)
const env = process.env.NODE_ENV
// console.log(process.env)
export const host = hosts[env]

export const otc_baseUrl = otc_url[env]

export const dapi_baseUrl = dapi_Url[env]
