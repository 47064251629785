import Cookies from 'js-cookie'
import moment from 'moment'

const TokenKey = 'trade_token'
const Account = 'trade_account'
const Verify = 'verify'
const Currency = 'currency'
const isShow = 'show_unread_count'
const lang = 'lang'
const futUnit = 'futUnit'
const deliveryUnit = 'deliveryUnit'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getAccount() {
  return Cookies.get(Account)
}
export function setAccount(account) {
  return Cookies.set(Account, account)
}
export function removeAccount() {
  return Cookies.remove(Account)
}

export function getVerify() {
  return Cookies.get(Verify)
}
export function setVerify(data) {
  return Cookies.set(Verify, data)
}

export function getCurrency() {
  return Cookies.get(Currency)
}
export function setCurrency(data) {
  return Cookies.set(Currency, data)
}
export function removeCurrency() {
  return Cookies.remove(Currency)
}

export function getUnreadCount() {
  return Cookies.get(isShow)
}
export function setUnreadCount(value) {
  return Cookies.set(isShow, value)
}
export function removeUnreadCount() {
  return Cookies.remove(isShow)
}

export function getLanguage() {
  return Cookies.get(lang)
}
export function setLanguage(value) {
  return Cookies.set(lang, value)
}
export function removeLanguage() {
  return Cookies.remove(lang)
}

export function getFutUnit() {
  return Cookies.get(futUnit)
}
export function setFutUnit(value) {
  return Cookies.set(futUnit, value)
}
export function removeFutUnit() {
  return Cookies.remove(futUnit)
}

export function getDeliveryUnit() {
  return Cookies.get(deliveryUnit)
}
export function setDeliveryUnit(value) {
  return Cookies.set(deliveryUnit, value)
}
export function removeDeliveryUnit() {
  return Cookies.remove(deliveryUnit)
}

// 表单参数存入时间&过期时间相关操作
export function setTime(key) {
  const params = { startTime: Date.now(), expired: moment(Date.now()).add(10, 'm')._d.getTime() }
  return sessionStorage.setItem(key, JSON.stringify(params))
}
export function getTime(key) {
  return sessionStorage.getItem(key)
}
export function removeTime(key) {
  return sessionStorage.removeItem(key)
}
