import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh-CN', // set locale
  fallbackLocale: 'zh-CN', // 默认语言设置，当其他语言没有的情况下，使用cn作为默认语言
  messages: {
    'zh-CN': require('@/lang/cn'),
    'zh-HK': require('@/lang/tr'),
    'en': require('@/lang/en')
  } // set locale messages
})

export default i18n
