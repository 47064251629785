import request from '@/utils/request.js'

export default {
  // 获取常用联系人列表
  insideTransList() {
    return request({
      url: '/inside/trans/list'
    })
  },
  // 新增联系人
  insideTransAdd(data) {
    return request({
      url: '/inside/trans/add',
      method: 'post',
      data
    })
  },
  // 添加备注
  insideTransEdit(data) {
    return request({
      url: '/inside/trans/edit',
      method: 'post',
      data
    })
  },
  // 删除联系人
  insideTransDel(data) {
    return request({
      url: '/inside/trans/del',
      method: 'post',
      data
    })
  },
  // 内部转账验证接口
  withdraTransfervalid(data) {
    return request({
      url: '/withdraw/transfervalid',
      method: 'post',
      data
    })
  },
  // 内部转账验证接口
  withdrawTransfer(data) {
    return request({
      url: '/withdraw/transfer',
      method: 'post',
      data
    })
  },
  // 内部转账开关
  userUpdatetransferopen(data) {
    return request({
      url: '/user/updatetransferopen',
      method: 'post',
      data
    })
  },
  // 转账额度提醒
  withdrawTransferquota(data) {
    return request({
      url: '/withdraw/transferquota',
      method: 'post',
      data
    })
  }

}
