import Station from '@/api/station'

const handleData = data => {
  const result = [...data]
  result.unshift({
    id: 0,
    name: '全部通知',
    unread_count: 0
  })
  return result
}

const handleMessageList = data => {
  if (data && data.length > 0) {
    return data.map(item => {
      const temp = { ...item }
      temp.isShowDetail = true
      return temp
    })
  }
  return []
}

const handleCategoryData = () => {
  return state.category_list.map((item, index) => {
    const temp = { ...item }
    if (state.message_list.unread_num) {
      let total_unread = 0
      state.message_list.unread_num.forEach(el => {
        total_unread += el.unread_count
        if (el.category_id === temp.id) {
          temp.unread_count = el.unread_count
        }
      })
      if (index === 0) {
        temp.unread_count = total_unread
      }
    } else {
      temp.unread_count = 0
    }
    return temp
  })
}

const state = {
  category_list: [],
  message_list: {
    list: [],
    total: null,
    unread_num: {}
  },
  message_detail: {},
  read_list: [],
  unreadCount: null
}

const mutations = {
  setCateList(state, data) {
    state.category_list = handleData(data)
  },
  setMessageList(state, data) {
    state.message_list.list = handleMessageList(data.data_list)
    state.message_list.total = data.data_total
    state.message_list.unread_num = data.unread_num
    state.category_list = handleCategoryData()
  },
  setMessageDetail(state, data) {
    state.message_detail = data
  },
  setUnreadCount(state, data) {
    state.unreadCount = data.count > 99 ? '99+' : data.count
  }
}

const actions = {
  async getCategoryList({ commit }) {
    try {
      const res = await Station.getCategoryListApi()
      commit('setCateList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getMessageList({ commit }, data) {
    try {
      const res = await Station.getMessageListApi(data)
      commit('setMessageList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getMessageDetail({ commit }, data) {
    try {
      const res = await Station.getMessageDetailApi(data)
      //   res = {
      //     data: {
      //       content: 'You have successfully authorized a new device or in a new location to sign into your Binance accoun You have successfully authorized a new device or in a new location to sign into your Binance accoun You have successfully authorized a new device or in a new location to sign into your Binance accoun',
      //       create_time: '',
      //       id: 0,
      //       little_title: '',
      //       message_id: 0,
      //       url: ''
      //     }
      //   }
      commit('setMessageDetail', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setAllRead() {
    try {
      await Station.setAllReadApi()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getUnreadCount({ commit }) {
    try {
      const res = await Station.getUnreadCountApi()
      commit('setUnreadCount', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
