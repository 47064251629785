import Trade from '@/api/futures/trade'
import Decimal from 'decimal.js'
import store from '@/store'
import { getNum, handleRatio, thousandths } from '@/utils'

const handleTickerList = (data) => {
  if (data && data.length) {
    return data.map((item) => {
      const temp = { ...item }
      return {
        ...temp,
        pair: `${item.coin}USDT 永续`,
        ratioStr: handleRatio(temp.ratio)
      }
    })
  }
  return []
}

const handleLike = (data) => {
  if (data && data.length) {
    return data.map((item) => {
      const temp = { ...item }
      return {
        ...temp,
        user_like: temp.user_like || 1,
        pair: `${item.coin}USDT 永续`,
        ratioStr: handleRatio(temp.ratio)
      }
    })
  }
  return []
}

const handleLevel = (data) => {
  if (data.levers && data.levers.length) {
    const tempMarks = {}
    const marksLevel = {}
    const sliderBarCount = Decimal.div(data.levers[0].max_leverage, 5).toNumber()
    tempMarks[1] = '1x'
    for (let index = 1; index < 6; index++) {
      tempMarks[index * sliderBarCount] = `${index * sliderBarCount}x`
    }
    data.levers.forEach((item) => {
      marksLevel[item.max_leverage] = item
    })
    return { tempMarks, marksLevel }
  }
  return {}
}

const handleCalResult = (data) => {
  const tempObj = { ...data }
  return tempObj
}

const handleAssetsInfo = (data) => {
  const tempObj = { ...data }
  const rate_data = store.state.user.rate_data
  if (rate_data && Object.keys(rate_data).length > 0) {
    const tempConvert = getNum(Decimal.mul(tempObj.balance, rate_data.rate).toNumber(), 2)
    const tempBondConvert = getNum(Decimal.mul(tempObj.bond, rate_data.rate).toNumber(), 2)
    const tempPnlConvert = getNum(Decimal.mul(tempObj.unprofit, rate_data.rate).toNumber(), 2)
    tempObj.convert = tempConvert
      ? `≈ ${rate_data.ratesymbol} ${thousandths(tempConvert)}`
      : `≈ ${rate_data.ratesymbol} 0.00`
    tempObj.convertBond = tempBondConvert
      ? `≈ ${rate_data.ratesymbol} ${thousandths(tempBondConvert)}`
      : `≈ ${rate_data.ratesymbol} 0.00`
    tempObj.convertPnl = tempPnlConvert
      ? `≈ ${rate_data.ratesymbol} ${thousandths(tempPnlConvert)}`
      : `≈ ${rate_data.ratesymbol} 0.00`
  } else {
    tempObj.convert = ''
    tempObj.convertBond = ''
    tempObj.convertPnl = ''
  }
  tempObj.balanceTotal = thousandths(getNum(tempObj.balance, 4))
  tempObj.bondTotal = thousandths(getNum(tempObj.bond, 4))
  tempObj.unprofitTotal = thousandths(getNum(tempObj.unprofit, 4))
  return tempObj
}

const state = {
  tickersList: [],
  tickersInfo: {},
  tradeLevel: {}, // 杠杆信息
  marksList: {},
  marks: {},
  assetInfo: {},
  allMargin: [],
  calcResult: {
    bond: '-', // 起始保证金
    earn: '-', // 收益
    rate: '', // 回报率
    price: '-' // 强平价格
  },
  transDetail: {},
  futHoldDetail: [],
  userLikeList: [],
  searchTickerList: [],
  futInitVal: {},
  fiveList: {
    bidList: [],
    askList: []
  },
  newdealList: [],
  klineList: [],
  preference: localStorage.getItem('Preference')
    ? JSON.parse(localStorage.getItem('Preference'))
    : '',
  otcLegalCurrencyList: [],
  futStandard: localStorage.getItem('isFutStandard') || 1
}

const mutations = {
  setIsStandard(state, data) {
    state.futStandard = data
    localStorage.setItem('isFutStandard', data)
  },
  setPreference(state, data) {
    state.preference = data
    localStorage.setItem('Preference', JSON.stringify(data))
  },
  setTickerList(state, data) {
    state.tickersList = handleTickerList(data)
    store.commit('quotes/setTableList', state.tickersList)
  },
  setTickerInfo(state, data) {
    state.tickersInfo = data
    state.tickersInfo.pair = `${data.coin}USDT 永续`
  },
  setTradeLevel(state, data) {
    state.tradeLevel = data
    const { tempMarks, marksLevel } = handleLevel(data)
    state.marksList = marksLevel
    state.marks = tempMarks
  },
  setAssetsInfo(state, data) {
    state.assetInfo = handleAssetsInfo(data)
    store.commit('futuresOrder/updateAssetsData', state.assetInfo)
  },
  setAllMargin(state, data) {
    state.allMargin = data
  },
  setCalcResult(state, data) {
    state.calcResult = handleCalResult(data)
  },
  resetCalcResult(state) {
    state.calcResult = {
      bond: '-', // 起始保证金
      earn: '-', // 收益
      rate: '', // 回报率
      price: '' // 强平价格
    }
  },
  setTransDetail(state, data) {
    state.transDetail = data
  },
  setFutHoldDetail(state, data) {
    state.futHoldDetail = data
  },
  setMarketLike(state, data) {
    state.userLikeList = handleLike(data)
    store.commit('quotes/setTableList', state.userLikeList)
  },
  searchTicker(state, data) {
    state.searchTickerList = handleTickerList(data)
  },
  setFutInitVal(state, data) {
    state.futInitVal = data
  },
  getFivelist(state, data) {
    state.fiveList.bidList = data.bid
    state.fiveList.askList = data.ask
  },
  getNewdeal(state, data) {
    if (data && data.length > 0) {
      data.forEach((item) => {
        state.newdealList.push([item.p, item.q, item.t, item.m])
      })
    }
  },
  getKlineList(state, data) {
    if (data && data.length > 0) {
      data.forEach((item) => {
        state.klineList.push([item.t, item.o, item.h, item.l, item.c, item.v])
      })
    }
  },
  resetTickerInfo(state) {
    state.tickersInfo = {}
  },
  resetKlineData(state) {
    state.klineList = []
  },
  resetTradeData(state) {
    state.newdealList = []
  },
  resetFivelistData(state) {
    state.fiveList.bidList = []
    state.fiveList.askList = []
  },
  otcLegalCurrency(state, data) {
    state.otcLegalCurrencyList = data
  }
}

const actions = {
  // 获取交易对信息
  async getTickerList({ commit }, params) {
    try {
      const res = await Trade.getTickerListApi(params)
      commit('setTickerList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取单个合约币信息
  async getTickerInfo({ commit }, params) {
    try {
      const res = await Trade.getTickerInfoApi(params)
      commit('setTickerInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取当前币种杠杆信息
  async getTradeLevel({ commit }, id) {
    try {
      const res = await Trade.getTradeLevelApi(id)
      commit('setTradeLevel', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 计算收益 || 强平价格
  async toCalc({ commit }, params) {
    try {
      const res = await Trade.toCalcApi(params)
      if (res.data) {
        commit('setCalcResult', res.data)
      } else {
        return res
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取资产统计
  async getAssetsInfo({ commit }, id) {
    try {
      const res = await Trade.getAssetsInfoApi(id)
      commit('setAssetsInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取杠杆与保证金设置
  async getAllMargin({ commit }, params) {
    try {
      const res = await Trade.getAllMarginApi(params)
      commit('setAllMargin', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取合约账户详情
  async getFutHoldDetail({ commit }, params) {
    try {
      const res = await Trade.getFutHoldDetailApi(params)
      commit('setFutHoldDetail', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取划转账户详情
  async getTransDetail({ commit }, params) {
    try {
      const res = await Trade.getTransDetailApi(params)
      commit('setTransDetail', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 划转
  async toTrans(no, params) {
    try {
      await Trade.toTransApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 合约获取用户自选
  async getMarketLike({ commit }, params) {
    try {
      const res = await Trade.getMarketLikeApi(params)
      commit('setMarketLike', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 搜索合约交易对
  async searchTicker({ commit }, params) {
    try {
      const res = await Trade.searchTickerApi(params)
      commit('searchTicker', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 新增/取消自选
  async userLike({ commit }, params) {
    try {
      await Trade.userLikeApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 合约--下单
  async placeOrder(no, params) {
    try {
      await Trade.placeOrderApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取合约交易页面初始值（仓位模式、杠杆）
  async getTradeSet({ commit }, params) {
    try {
      const res = await Trade.getTradeSetApi(params)
      commit('setFutInitVal', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取合约买卖5订单
  async getFivelist({ commit }, params) {
    try {
      const res = await Trade.getFivelistApi(params)
      commit('getFivelist', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取最新成交
  async getNewdealList({ commit }, params) {
    try {
      const res = await Trade.getNewdealListApi(params)
      commit('getNewdeal', res.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 新增自选_新
  async addlike(no, params) {
    try {
      await Trade.addlikeApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 取消自选_新
  async removelike(no, params) {
    try {
      await Trade.removelikeApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取kline
  async getKlineList({ commit }, params) {
    try {
      const res = await Trade.getKlineListApi(params)
      commit('resetKlineData')
      commit('getKlineList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 调整杠杆倍数
  async updateUserLeverage(no, params) {
    try {
      await Trade.updateUserLeverageApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 保证金模式切换
  async changeMarginType(no, params) {
    try {
      await Trade.changeMarginTypeApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 调整仓位模式
  async updatePosition(no, params) {
    try {
      await Trade.updatePositionApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 加载法币(otc交易币种)列表
  async otcLegalCurrency({ commit }) {
    try {
      const res = await Trade.otcLegalCurrencyApi()
      commit('otcLegalCurrency', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
