import Help from '@/api/help'

const state = {
  article_list: []
}

const mutations = {
  getArticleCenter(state, data) {
    state.article_list = data
  }
}

const actions = {
  async getArticleCenter({ commit }) {
    try {
      const res = await Help.getArticleCenterApi()
      //   console.log(res.data)
      commit('getArticleCenter', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
