import Authenticate from '@/api/authenticate'

const state = {
  ident_info: {}
}

const mutations = {
  setIdentInfo(state, data) {
    state.ident_info = data
    // state.ident_info = {
    //   area: '86',
    //   birthdate: '',
    //   countryid: 40,
    //   idcard: '610424199504075689',
    //   idcardimage1: '',
    //   idcardimage2: '',
    //   idcardlevel: 2,
    //   idcardstate: 3,
    //   idcardtype: 1,
    //   idcardvideo: '',
    //   middlename: '',
    //   name: 'test',
    //   reason: '',
    //   surname: '',
    //   userid: 7,
    //   verifytype: 2
    // }
  }
}

const actions = {
  async getIdentInfo({ commit }) {
    try {
      const res = await Authenticate.getIdentInfoApi()
      commit('setIdentInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async addJunior(no, data) {
    try {
      await Authenticate.addJuniorApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateJunior(no, data) {
    try {
      await Authenticate.updateJuniorApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateSenior(no, data) {
    try {
      await Authenticate.updateSeniorApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async withdrawAmtInfo() {
    try {
      const res = await Authenticate.withdrawAmtInfo()
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
