import request from '@/utils/request'
export default {
  // 获取交易费率列表
  getTradeListApi(type) {
    const url = type === 1 ? '/service/tradeset/rate' : '/service/futtradeset/rate'
    return request({
      url,
      method: 'get'
    })
  },
  // 获取充提费率
  getDepositWithdrawApi() {
    return request({
      url: '/service/trade/rate',
      method: 'get'
    })
  },
  // 获取app下载地址
  getDownloadUrlApi() {
    return request({
      url: '/service/download/address',
      method: 'get'
    })
  },
  // 帮助中心列表
  getArticleListApi() {
    return request({
      url: '/article/center',
      method: 'get'
    })
  },
  // 帮助中心详情
  getArticleDetailApi(id) {
    return request({
      url: `/article/detail/${id}`,
      method: 'get'
    })
  },
  // 帮助中心搜索
  getArticleSearchApi(data) {
    return request({
      url: `/article/search?keyword=${data.keyword}&page=${data.page}&size=${data.size}`,
      method: 'get',
      data
    })
  }
}
