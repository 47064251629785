import request from '@/utils/request'

export default {
  // 获取所有交易对信息
  getTickerListApi(params) {
    return request({
      url: '/market/tickers',
      method: 'get',
      headers: { model_type: 'DAPI' },
      params
    })
  },
  // 获取单个合约币信息
  getTickerInfoApi(data) {
    return request({
      url: '/market/ticker',
      method: 'get',
      headers: { model_type: 'DAPI' },
      params: data
    })
  },
  // 获取当前币种杠杆信息
  getTradeLevelApi(id) {
    return request({
      url: `/calc/tradelevel/${id}`,
      headers: { model_type: 'DAPI' },
      method: 'get'
    })
  },
  // 计算收益 || 强平价格
  toCalcApi(data) {
    const url = `/calc/${data.type}`
    delete data.type
    return request({
      url,
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 获取合约资产统计+资产币种列表
  getAssetsInfoApi() {
    return request({
      url: '/asset/getholdassets',
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  },
  // 获取杠杆与保证金设置
  getAllMarginApi() {
    return request({
      url: '/market/getallmarginset',
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  },
  // 合约账户详情
  getFutHoldDetailApi(data) {
    return request({
      url: '/asset/getfutcoinholddetail',
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 获取划转账户详情
  getTransDetailApi(data) {
    return request({
      url: '/futures/transferaccount',
      method: 'post',
      // headers: { model_type: 'DAPI' },
      data
    })
  },
  // 划转
  toTransApi(data) {
    return request({
      url: '/futures/transfer',
      method: 'post',
      // headers: { model_type: 'DAPI' },
      data
    })
  },
  // 合约获取用户自选
  getMarketLikeApi() {
    return request({
      url: '/asset/like',
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  },
  // 搜索合约交易对
  searchTickerApi(data) {
    return request({
      url: `/asset/searchticker`,
      method: 'get',
      headers: { model_type: 'DAPI' },
      params: data
    })
  },
  // 合约--下单
  placeOrderApi(data) {
    return request({
      url: `/trade/placeorder`,
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 获取合约交易页面初始值（仓位模式、杠杆）
  getTradeSetApi(params) {
    const tempParams = { coin: params.coin }
    return request({
      url: '/asset/gethold',
      method: 'get',
      headers: { model_type: 'DAPI' },
      params: tempParams
    })
  },
  // 获取合约买卖5订单
  getFivelistApi(data) {
    return request({
      url: `market/fivelistnew?coin=${data.coin}`,
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  },
  // 获取最新成交
  getNewdealListApi(data) {
    return request({
      url: `/market/newdeal?coin=${data.coin}`,
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  },
  // 新增自选_新
  addlikeApi(data) {
    return request({
      url: `/asset/addlike`,
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 取消自选_新
  removelikeApi(data) {
    return request({
      url: `asset/removelike`,
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 获取kline
  getKlineListApi(data) {
    return request({
      url: `/market/klinenew?coin=${data.coin}&kline_type=${data.kline_type}`,
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  },
  // 保证金模式切换
  changeMarginTypeApi(data) {
    return request({
      url: '/trade/marginType',
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 调整杠杆倍数
  updateUserLeverageApi(data) {
    return request({
      url: '/trade/updateuserleverage',
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 调整仓位模式
  updatePositionApi(data) {
    return request({
      url: '/trade/positionSide/dual',
      method: 'post',
      headers: { model_type: 'DAPI' },
      data
    })
  },
  // 加载合约账户总资产+全部资产列表
  getholdAssetsApi() {
    return request({
      url: '/asset/getholdassets',
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  }
}
