<template>
  <div style="background-color: #070707;">
    <Headers />
    <router-view></router-view>
  </div>
</template>
<script>
import { Headers } from '@/layout/components'
export default {
  components: {
    Headers
  }
}
</script>

