import request from '@/utils/request'
export default {
  // 获取用户认证详情
  getIdentInfoApi() {
    return request({
      url: '/ident/getidentinfo',
      method: 'get'
    })
  },
  // 提交初级认证
  addJuniorApi(data) {
    return request({
      url: `/ident/addjunior`,
      method: 'post',
      data
    })
  },
  // 重新提交初级认证
  updateJuniorApi(data) {
    return request({
      url: `/ident/updatejunior`,
      method: 'post',
      data
    })
  },
  // 高级认证
  updateSeniorApi() {
    return request({
      url: `/ident/updatesenior`,
      method: 'post'
    })
  },
  // kyc限额
  withdrawAmtInfo() {
    return request({
      url: '/withdraw/amt/info',
      method: 'get'
    })
  }
}
