import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import LoginLayout from '@/layout/loginLayout'
import headerLayout from '@/layout/headerLayout'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('@/views/home/index'),
        name: 'Home',
        meta: { title: '全球领先的一站式加密货币服务平台 | Tokex' }
      }
    ]
  },
  {
    path: '/login',
    component: LoginLayout,
    redirect: '/login',
    children: [
      {
        path: '/',
        component: () => import('@/views/login/index'),
        name: 'Login',
        meta: { title: '登录 | Tokex' }
      },
      {
        path: 'safeVerify',
        component: () => import('@/views/login/safeVerify'),
        name: 'SafeVerify',
        meta: { title: '安全验证 | Tokex' }
      },
      {
        path: 'tips',
        name: 'Tips',
        meta: { title: '温馨提示 | Tokex' },
        component: () => import('@/views/login/forget/tips')
      },
      {
        path: 'forget',
        name: 'Forget',
        meta: { title: '忘记密码 | Tokex' },
        component: () => import('@/views/login/forget/index')
      },
      {
        path: 'forget/reset',
        name: 'Reset',
        meta: { title: '忘记密码 | Tokex' },
        component: () => import('@/views/login/forget/reset')
      },
      {
        path: 'forget/success',
        name: 'Success',
        meta: { title: '忘记密码 | Tokex' },
        component: () => import('@/views/login/forget/success')
      }
    ]
  },
  {
    path: '/register',
    component: LoginLayout,
    redirect: '/register',
    children: [
      {
        path: '/',
        meta: { title: '注册 | Tokex' },
        component: () => import('@/views/register/index'),
        name: 'Register'
      },
      {
        path: 'accountVerify',
        name: 'AccountVerify',
        meta: { title: '注册 | Tokex' },
        component: () => import('@/views/register/accountVerify')
      },
      {
        path: 'success',
        name: 'RegisSuccess',
        meta: { title: '注册 | Tokex' },
        component: () => import('@/views/register/regisSuccess')
      }
    ]
  },
  {
    path: '/account/security',
    component: Layout,
    // redirect: '/safeCenter',
    children: [
      {
        path: '/',
        meta: { title: '安全中心 | Tokex' },
        component: () => import('@/views/safeCenter/index'),
        name: 'SafeCenter'
      }
    ]
  },
  {
    path: '/account',
    component: Layout,
    redirect: '/account/list',
    children: [
      // {
      //   path: 'dashboard',
      //   meta: { title: '资产 | Tokex' },
      //   component: () => import('@/views/assets/index'),
      //   name: 'Assets'
      // },
      {
        path: 'list',
        meta: { title: '资产记录 | Tokex' },
        component: () => import('@/views/assets/list'),
        name: 'AssetsList'
      }
    ]
  },
  {
    path: '/my/wallet/funding/list',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/assets/list/otcList'),
        name: 'OtcAssetsList',
        meta: {
          title: '资产流水 | Tokex'
        }
      }
    ]
  },
  {
    path: '/my/wallet/account/detail',
    component: Layout,
    children: [
      {
        path: ':coin',
        component: () => import('@/views/assets/list/detail'),
        name: 'AssetsDetail',
        meta: {
          title: '资产详情 | Tokex'
        }
      }
    ]
  },
  {
    path: '/my/wallet/account',
    component: Layout,
    children: [
      {
        path: ':account',
        component: () => import('@/views/assets/index'),
        name: 'Wallet',
        meta: {
          title: '钱包总览 | Tokex'
        }
      }
    ]
  },
  {
    path: '/my/wallet/account/main/deposit/crypto',
    component: Layout,
    children: [
      {
        path: ':coin',
        component: () => import('@/views/assets/recharge'),
        name: 'Recharge',
        meta: {
          title: '充值 - 钱包 | Tokex'
        }
      }
    ]
  },
  {
    path: '/my/wallet/account/main/withdraw/crypto',
    component: Layout,
    children: [
      {
        path: ':coin',
        component: () => import('@/views/assets/withdraw'),
        name: 'Withdraw',
        meta: {
          title: '提现 - 钱包 | Tokex'
        }
      }
    ]
  },
  {
    path: '/my/wallet',
    component: Layout,
    children: [
      {
        path: ':account',
        component: () => import('@/views/assets/index'),
        name: 'Funding',
        meta: {
          title: '资金账户 | Tokex'
        }
      }
    ]
  },
  {
    path: '/account/kyc/personal',
    component: Layout,
    // redirect: '/authenticate',
    children: [
      {
        path: '/',
        meta: { title: '身份认证 | Tokex' },
        component: () => import('@/views/authenticate/index'),
        name: 'Authenticate'
      }
    ]
  },
  {
    path: '/markets',
    component: Layout,
    // redirect: '/quotes',
    children: [
      {
        path: ':type',
        component: () => import('@/views/quotes/index'),
        name: 'Quotes',
        meta: {
          title: '行情中心 | Tokex'
        }
      }
    ]
  },
  {
    path: '/referral',
    component: Layout,
    // redirect: '/invite',
    children: [
      {
        path: '',
        meta: { title: '邀请好友 | Tokex' },
        component: () => import('@/views/invite/index'),
        name: 'Invite'
      }
    ]
  },
  {
    path: '/inmail',
    component: Layout,
    // redirect: '/stationLetter',
    children: [
      {
        path: '',
        meta: { title: '通知 | Tokex' },
        component: () => import('@/views/stationLetter/index'),
        name: 'StationLetter'
      }
    ]
  },
  {
    path: '/download',
    component: Layout,
    // redirect: '/download',
    children: [
      {
        path: '',
        meta: { title: 'APP下载 | Tokex' },
        component: () => import('@/views/download/index'),
        name: 'Download'
      }
    ]
  },
  {
    path: '/order/spot',
    component: Layout,
    // redirect: '/order/exchangeOrder',
    children: [
      {
        path: ':orderType',
        meta: { title: '现货订单 | Tokex' },
        component: () => import('@/views/spot/order/index'),
        name: 'SpotOrder'
      }
    ]
  },
  {
    path: '/spot',
    component: headerLayout,
    children: [
      {
        path: ':tradePair',
        meta: { title: '现货交易 | Tokex' },
        component: () => import('@/views/spot/transaction/index'),
        name: 'Transaction'
      }
    ]
  },
  {
    path: '/futures',
    component: headerLayout,
    children: [
      {
        path: ':tradePair',
        meta: { title: '合约交易 | Tokex' },
        component: () => import('@/views/futures/trade/index'),
        name: 'FuturesTrade'
      }
    ]
  },
  {
    path: '/futures/order',
    component: Layout,
    children: [
      {
        path: ':orderType',
        meta: { title: '合约订单 | Tokex' },
        component: () => import('@/views/futures/order/index'),
        name: 'FuturesOrder'
      }
    ]
  },
  {
    path: '/delivery',
    component: headerLayout,
    children: [
      {
        path: ':tradePair',
        meta: { title: '合约交易 | Tokex' },
        component: () => import('@/views/delivery/trade/index'),
        name: 'DeliveryTrade'
      }
    ]
  },
  {
    path: '/delivery/order',
    component: Layout,
    children: [
      {
        path: ':orderType',
        meta: { title: '合约订单 | Tokex' },
        component: () => import('@/views/delivery/order/index'),
        name: 'DeliveryOrder'
      }
    ]
  },
  {
    path: '/fee',
    component: Layout,
    redirect: '/fee/trade',
    children: [
      {
        path: 'trade',
        meta: { title: '费率标准 | Tokex' },
        component: () => import('@/views/footer/help/rate/trade'),
        name: 'TradeRate'
      },
      {
        path: 'cryptoFee',
        meta: { title: '费率标准 | Tokex' },
        component: () => import('@/views/footer/help/rate/cryptoFee'),
        name: 'CryptoFee'
      }
    ]
  },
  {
    path: '/apiKey',
    component: Layout,
    // redirect: '/download',
    children: [
      {
        path: '/',
        meta: { title: 'ApiKey管理' },
        component: () => import('@/views/apiKey/index'),
        name: 'ApiKey'
      }
    ]
  },
  {
    path: '/help',
    component: Layout,
    redirect: '/help',
    children: [
      {
        path: '/',
        meta: { title: '帮助中心 | Tokex' },
        component: () => import('@/views/help/index'),
        name: 'Help'
      },
      {
        path: '/articleList',
        meta: { title: '常见问题 | Tokex' },
        component: () => import('@/views/help/articleList'),
        name: 'ArticleList'
      },
      {
        path: '/articleDetail',
        meta: { title: '常见问题 | Tokex' },
        component: () => import('@/views/help/articleDetail'),
        name: 'ArticleDetail'
      },
      {
        path: '/search',
        meta: { title: '搜索 | Tokex' },
        component: () => import('@/views/help/search'),
        name: 'Search'
      }
    ]
  },
  {
    path: '/buy',
    component: Layout,
    redirect: '/buy/fast',
    children: [
      {
        path: 'fast',
        meta: { title: '快捷买币 | Tokex' },
        component: () => import('@/views/buyCoins/buyFast'),
        name: 'BuyFast'
      },
      {
        path: 'custorm',
        meta: { title: '自选买币 | Tokex' },
        component: () => import('@/views/buyCoins/buyCustorm'),
        name: 'BuyCustorm'
      },
      {
        path: 'order',
        meta: { title: '我的订单 | Tokex' },
        component: () => import('@/views/buyCoins/order'),
        name: 'Order'
      },
      {
        path: 'order_detail',
        meta: { title: '订单详情 | Tokex' },
        component: () => import('@/views/buyCoins/orderDetail/index'),
        name: 'OrderDetail'
      },
      {
        path: 'order_pay',
        meta: { title: '快捷买币 | Tokex' },
        component: () => import('@/views/buyCoins/orderPay'),
        name: 'OrderPay'
      },
      {
        path: 'pay_method',
        meta: { title: '收款方式 | Tokex' },
        component: () => import('@/views/buyCoins/payMethod'),
        name: 'PayMethod'
      },
      {
        path: 'business_info',
        meta: { title: '商家主页 | Tokex' },
        component: () => import('@/views/buyCoins/businessInfo'),
        name: 'BusinessInfo'
      },
      {
        path: 'business_manager',
        meta: { title: '商家后台 | Tokex' },
        component: () => import('@/views/buyCoins/businessManager'),
        name: 'BusinessManager'
      },
      {
        path: 'business_ad',
        meta: { title: '商家后台 | Tokex' },
        component: () => import('@/views/buyCoins/businessManager/businessAd'),
        name: 'BusinessAd'
      },
      {
        path: 'apply_business',
        meta: { title: '申请商家 | Tokex' },
        component: () => import('@/views/buyCoins/applyBusiness'),
        name: 'ApplyBusiness'
      },
      {
        path: 'apply_business_form',
        meta: { title: '申请商家 | Tokex' },
        component: () => import('@/views/buyCoins/applyBusiness/applyBusinessForm'),
        name: 'ApplyBusinessForm'
      }
    ]
  },
  {
    path: '/transfer',
    component: Layout,
    redirect: '/transfer',
    children: [
      {
        path: '/',
        meta: { title: '内部转账 | Tokex' },
        component: () => import('@/views/transferMoney/index'),
        name: 'TransferMoney'
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    // redirect: '/footer/aboutUs',
    children: [
      {
        path: ':menuType',
        meta: { title: '关于我们 | Tokex' },
        component: () => import('@/views/footer/aboutUs/index'),
        name: 'AboutUs'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
