<template>
  <el-dropdown-menu ref="environDropdown" slot="dropdown" :style="{'margin-top': marginTop}" class="environ_dropdown_menu">
    <el-dropdown-item>
      <div class="environ_body">
        <div class="environ_block scroll_view">
          <div class="environ_title">{{$t('message.header.language')}}</div>
          <div class="environ_item"
            v-for="item in languageList"
            :style="{'color': currentLanguage === item.name ? basicColor : ''}"
            :key="item.value"
            @click="checkLanguage(item)">
            <span>{{item.name}}</span>
            <i v-if="(currentLanguage === item.name)" class="el-icon-check"></i>
          </div>
        </div>
        <div class="environ_block scroll_view">
          <div class="environ_title">{{$t('message.header.currency')}}</div>
          <div class="environ_item"
            :style="{'color': currentCurrency === index ? basicColor : ''}"
            v-for="(item, index) in currencyList"
            :key="item.tocoin"
            @click="checkCurrency({index, item})">
            <span>{{item.tocoin}}</span>
            <i v-if="currentCurrency === index" class="el-icon-check"></i>
          </div>
        </div>
      </div>
    </el-dropdown-item>
  </el-dropdown-menu>
</template>
<script>
import { getToken } from '@/utils/auth'
export default {
  inject: ['reload'],
  props: {
    marginTop: {
      type: String,
      default: ''
    },
    languageList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currencyList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      currentLanguage: null,
      currentCurrency: null,
      isLogin: getToken(),
      basicColor: this.GLOBAL.basicColor
    }
  },
  methods: {
    checkLanguage(item) {
      console.log(item)
      this.currentLanguage = item.name
      this.$i18n.locale = item.value
      this.$store.commit('user/setLanguage', item.value)
    },
    checkCurrency({ index, item }) {
      this.currentCurrency = index
      this.$store.commit('user/setCurrency', item)
      this.$store.dispatch('user/setRateCountry', item).then(() => {
        this.reload()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.environ_dropdown_menu{
  margin-bottom: 0 !important;
  .el-dropdown-menu__item{
    min-width: 300px;
    background-color: #2B3139 !important;
    border-color: #2B3139 !important;
    padding: 0;
  }
  .environ_body{
    display: flex;
    padding: 16px 0;
    max-height: 304px;
    font-weight: 500;
    .environ_block{
      flex: 1;
      border-right: 2px solid #373B43;
      &:last-child{
        border: none;
      }
      .environ_title{
        font-size: 14px;
        color: #B7BDC6;
        margin-bottom: 16px;
        line-height: 20px;
        padding-left: 12px;
      }
      .environ_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #B7BDC6;
        padding: 0 12px;
        // line-height: 30px;
        &:last-child{
          margin-bottom: 0;
        }
        &:hover{
          // color: $basicColor;
          background-color: #44454C;
        }
        &:first-child{
          &:hover{
            color: #B7BDC6;
          }
        }
      }
    }
  }
}
</style>
