import request from '@/utils/request'
export default {
  // 获取banner
  getBannerListApi(data) {
    return request({
      url: '/home/banner',
      method: 'get',
      params: data
    })
  },
  // 获取币种信息
  getSymbolListApi(type) {
    return request({
      url: `/home/gethomesymbollistpc?get_type=${type}`,
      method: 'get'
    })
  },
  // 获取币种信息--新
  getSymbolListNewApi(type) {
    return request({
      url: `/home/gethomesymbollistpc?get_type=${type}`,
      method: 'get'
    })
  },
  // 搜索交易币 || 计价币
  searchTickerApi(data) {
    return request({
      url: `/home/searchticker`,
      method: 'post',
      data
    })
  }
}
