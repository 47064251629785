import moment from 'moment'
import Decimal from 'decimal.js'
import store from '@/store'
import JSEncrypt from 'jsencrypt'

/** @augments
  * @method encryption() 加密
  * @param {String}
  * publicKey:公钥；password:密码
*/
export const encryption = (password) => {
  const encryptor = new JSEncrypt()
  // 公钥
  const publicKey = '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA76ldrv3xNW04lK8H7hXHV3glcRhjJWPmm4MORAIeZKVxN+BnnZ0dmeAkA9VV7YIrCJzH2RqYL9Pk+HsjNabEZwJghwHDLCXUs/vSbQC+Fe3APwl2STC/tVXDQC/gdilqK+55mEJ0dqsyZGW5NT1ACX4VqBWU3UbfHO8WckEOsf9Bd8XubJnUCCbOSgvz/wfEoYNI0RiUes3yrEqguBkXPEm99+5W6dFWoQ7MubgDS0EkHFvzgCMtY5ghixoU2pInbbID0FsLGHYPMnlKF/0IhnXp3M83zoMqdphYjF6fQc0C7Pr0S9b1sE50yqKll4l84assMGUttH7LhNuZ6M15CwIDAQAB-----END PUBLIC KEY-----'
  encryptor.setPublicKey(publicKey)
  const pwd = encryptor.encrypt(password)
  return pwd
}

// 获取ip
export function getUserIp(onNewIP) {
  const MyPeerConnection =
    window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
  const pc = new MyPeerConnection({
    iceServers: []
  })
  const noop = () => {}
  const localIPs = {}
  const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
  const iterateIP = (ip) => {
    if (!localIPs[ip]) onNewIP(ip)
    localIPs[ip] = true
  }
  pc.createDataChannel('')
  pc.createOffer()
    .then((sdp) => {
      sdp.sdp.split('\n').forEach(function(line) {
        if (line.indexOf('candidate') < 0) return
        line.match(ipRegex).forEach(iterateIP)
      })
      pc.setLocalDescription(sdp, noop, noop)
    })
    .catch((reason) => {})
  pc.onicecandidate = (ice) => {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    ) {
      return
    }
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP)
  }
}

// 时间转换
export function getDate(time, format) {
  return moment(time * 1000).format(format)
}

// 处理涨幅数值
export function handleRatio(data) {
  if (!data) {
    return
  }
  if (typeof data !== 'string') {
    data.toString()
  }
  const tempNum = data.replace(/[+-]/g, '')
  // const tempRatio = Number(tempNum).toFixed(2)
  const tempRatio = getNum(tempNum, 2)
  const tempRatioStr = tempRatio == 0 ? '' : data.indexOf('-') > -1 ? '-' : '+'
  return `${tempRatioStr}${tempRatio}%`
}

// 防抖
export function debounce(func, delay = 1000, immediate = false) {
  let timer = null
  // 不能用箭头函数
  return function() {
    // 在时间内重复调用的时候需要清空之前的定时任务
    if (timer) {
      clearTimeout(timer)
    }
    // 适用于首次需要立刻执行的
    if (immediate && !timer) {
      func.apply(this, arguments)
    }
    timer = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}

// 节流
export const throttle = (fn, delay = 1000) => {
  // 距离上一次的执行时间
  let lastTime = 0
  return function() {
    const _this = this
    const _arguments = arguments
    const now = new Date().getTime()
    // 如果距离上一次执行超过了delay才能再次执行
    if (now - lastTime > delay) {
      fn.apply(_this, _arguments)
      lastTime = now
    }
  }
}

/**
 * 保留n位小数（截取）
 * @param num 需要处理的数字
 * @param digits 需要保留多少位数
 *
 */
export const getNum = (num, digits, fillZero = true) => {
  if (['', null, undefined, NaN].includes(num)) return
  if (typeof num === 'number') num = num.toString()
  if (typeof digits !== 'number') digits = Number(digits)
  if (!digits) {
    digits = 0
  }
  let res
  if (digits === 0) {
    const tempStr = num.indexOf('.') > -1 ? num.split('.')[0] : num
    res = tempStr
  } else {
    const tempStr = num.indexOf('.') > -1 ? num.substring(0, num.indexOf('.') + (digits + 1)) : num
    const arr = tempStr.split('.')
    if (fillZero) {
      // 小数位不足补0
      res =
        arr.length > 1
          ? `${arr[0]}.${arr[1].padEnd(digits, '0')}`
          : `${arr[0]}${'.'.padEnd(digits + 1, '0')}`
    } else {
      // 小数位不足不补0
      res = arr.length > 1 ? `${arr[0]}.${arr[1]}` : `${arr[0]}`
    }
  }
  return res
}

/**
 * 对数据进行格式化为k,m,b
 * @param {e} num 数据
 * @param {e} dot 保留的小数位， 不传默认不处理
 * 如果单位换算，直接保存两位
 * @returns
 */
export const transNumOtherUnit = (num, dot, FixTwo = 2) => {
  if (typeof num != 'number') {
    throw new Error('需传入number类型')
  }
  // debugger;
  const numData = num.toString()
  const numList = numData.split('.')
  const numInt = numList[0]
  const hasDot = numList.length == 2
  // 有小数位的时候
  let numDot = 0
  if (hasDot) {
    numDot = numList[1].toString().length
    if (dot >= 0) {
      numDot = dot
    }
  }
  let numK = num

  if (numInt.toString().length < 4) {
    numK = num.toFixed(numDot)
  }
  // 一千以内不用处理
  if (numInt.toString().length >= 4 && numInt.toString().length < 7) {
    numK = (num / 1000).toFixed(FixTwo || numDot) + 'K'
  }
  if (numInt.toString().length >= 7 && numInt.toString().length < 12) {
    numK = (num / 1000000).toFixed(FixTwo || numDot) + 'M'
  }
  if (numInt.toString().length >= 12) {
    numK = (num / 1000000000).toFixed(FixTwo || numDot) + 'B'
  }

  return numK
}

// 千分位处理
export const thousandths = (num) => {
  if (!num) return
  let tempNum
  if (typeof num === 'number') num = num.toString()
  const tempArr = num.split('.')
  tempNum = tempArr[0]
  if (tempNum && typeof tempNum !== 'number') {
    tempNum = Number(tempNum)
  }
  const res = `${tempNum.toLocaleString()}${tempArr[1] ? `.${tempArr[1]}` : ''}`
  return res
}

/**
 * 金额转换
 * @param money 需要转换的金额
 * @param digit 需要保留多少位数
 *
 */
export const simplifyMoney = (money = 0, digit = 2) => {
  const numStr = money.toString()
  const numSplit = numStr.split('.')[0]
  const roundNum = parseInt(money)
  const m = Math.pow(10, digit)
  if (store.state.user.language == 'zh-CN') {
    if (numSplit.length <= 4) {
      return `${numStr}`
    } else if (numSplit.length > 4 && numSplit.length <= 8) {
      const average = roundNum / 10000
      const round = parseInt(average * m) / m
      return `${new Decimal(round).toFixed(2, Decimal.ROUND_DOWN)}万`
    } else {
      const average = roundNum / 100000000
      const round = parseInt(average * m) / m
      return `${new Decimal(round).toFixed(2, Decimal.ROUND_DOWN)}亿`
    }
  } else {
    if (numSplit.length <= 2) {
      return `${numStr}`
    } else if (numSplit.length >= 3 && numSplit.length < 7) {
      const average = roundNum / 1000
      const round = parseInt(average * m) / m
      return `${new Decimal(round).toFixed(2, Decimal.ROUND_DOWN)}K`
    } else if (numSplit.length >= 7 && numSplit.length < 10) {
      const average = roundNum / 1000000
      const round = parseInt(average * m) / m
      return `${new Decimal(round).toFixed(2, Decimal.ROUND_DOWN)}M`
    } else {
      const average = roundNum / 1000000000
      const round = parseInt(average * m) / m
      return `${new Decimal(round).toFixed(2, Decimal.ROUND_DOWN)}B`
    }
  }
}

/**
 * 数字补零
 * @param {*} num
 * @returns
 */

export const numFillZero = (num) => {
  if (num < 10) {
    return '0' + num
  } else {
    return num
  }
}

export const toNumberStr = (num) => {
  // console.log(num)
  // // 正则匹配小数科学记数法
  // if (/^(\d+(?:\.\d+)?)(e)([\-]?\d+)$/.test(num)) {
  //   console.log(num)
  //   // 正则匹配小数点最末尾的0
  //   var temp = /^(\d{1,}(?:,\d{3})*\.(?:0*[1-9]+)?)(0*)?$/.exec(num.toFixed(digits))
  //   if (temp) {
  //     return temp[1]
  //   } else {
  //     return num.toFixed(digits)
  //   }
  // } else {
  //   return '' + num
  // }
  const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
  return num.toFixed(Math.max(0, (m[1] || '').length - m[2]))
}

// 获取开始时间：00:00:00
export const startTime = (time) => {
  const nowTimeDate = new Date(time)
  return nowTimeDate.setHours(0, 0, 0)
}
// 获取结束时间：23:59:59
export const endTime = (time) => {
  const nowTimeDate = new Date(time)
  return nowTimeDate.setHours(23, 59, 59)
}

// 时间选择禁用期限
export const disableTime = (time) => {
  // console.log(time)
  return endTime(time.getTime()) < startTime(Number(moment().subtract(6, 'M').format('x')))
}

// 设置默认开始时间
export const defaultStart = () => {
  return startTime(Number(moment().subtract(1, 'M').format('x')))
}

// otc时间格式化
export function formatData(date) {
  let formatDate
  const time = moment.duration(date, 'seconds') // 得到一个对象，里面有对应的时分秒等时间对象值
  const hours = time.hours()
  const minutes = time.minutes()
  const seconds = time.seconds()
  if (hours > 0) {
    formatDate = moment({ h: hours, m: minutes, s: seconds }).format(`HH:mm′ss″`)
  } else {
    formatDate = moment({ h: hours, m: minutes, s: seconds }).format(`mm′ss″`)
  }
  return formatDate
}

// 校验邮箱格式
export const validateEmail = (rule, value, callback) => {
  const reg = /^[\w-]+@[\w-]+(\.[\w-]+)+$/
  if (!value) {
    callback(new Error('请输入邮箱'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的邮箱格式！'))
  } else {
    callback()
  }
}
// 校验国内手机格式
export const validatePhone = (rule, value, callback) => {
  const reg = /^1[3456789]\d{9}$/g
  if (!value) {
    callback(new Error('请输入手机号'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机格式！'))
  } else {
    callback()
  }
}
// 校验国外手机格式
export const validateForeignPhone = (rule, value, callback) => {
  const reg = /^\d+$/
  if (!value) {
    callback(new Error('请输入手机号'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机格式！'))
  } else {
    callback()
  }
}
// 校验密码格式
export const validatePassword = (rule, value, callback) => {
  // (?=.*[$@~_^!%#?&*-])
  const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,32}$/
  if (!value) {
    callback(new Error('请输入密码'))
  } else if (!reg.test(value)) {
    callback(new Error('密码应为8-32位字符，必须包含1位大写字母、1位小写字母及1位数字'))
  } else {
    callback()
  }
}
// 校验验证码格式
export const validateCode = (rule, value, callback) => {
  const reg = /^\d{6}$/
  if (!value) {
    callback(new Error('请输入验证码'))
  } else if (!reg.test(value)) {
    callback(new Error('验证码必须为6位数字'))
  } else {
    callback()
  }
}
// 校验邀请码格式
export const validateInvite = (rule, value, callback) => {
  const reg = /^[A-Za-z\d]+$/
  if (value && !reg.test(value)) {
    callback(new Error('邀请码必须为数字和字母组合'))
  } else {
    callback()
  }
}

// 获取设备信息
export const getUserAgentInfo = () => {
  const userAgent = navigator.userAgent
  let version
  if (/opera/i.test(userAgent) || /OPR/i.test(userAgent)) {
    version = getVersion(userAgent, 'OPR/(\\d+\\.+\\d+)')
    return `Opera V${version}(${GetOs()})`
  } else if (/compatible/i.test(userAgent) && /MSIE/i.test(userAgent)) {
    version = getVersion(userAgent, 'MSIE (\\d+\\.+\\d+)')
    return `IE V${version}(${GetOs()})`
  } else if (/Edge/i.test(userAgent)) {
    version = getVersion(userAgent, 'Edge/(\\d+\\.+\\d+)')
    return `Edge V${version}(${GetOs()})`
  } else if (/Firefox/i.test(userAgent)) {
    version = getVersion(userAgent, 'Firefox/(\\d+\\.+\\d+)')
    return `Firefox V${version}(${GetOs()})`
  } else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
    version = getVersion(userAgent, 'Safari/(\\d+\\.+\\d+)')
    return `Safari V${version}(${GetOs()})`
  } else if (/Chrome/i.test(userAgent) && /Safari/i.test(userAgent)) {
    version = getVersion(userAgent, 'Chrome/(\\d+\\.+\\d+)')
    return `Chrome V${version}(${GetOs()})`
  } else if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    version = 11
    return `IE V${version}(${GetOs()})`
  }
}

function GetOs() {
  const sUserAgent = navigator.userAgent.toLocaleLowerCase()
  const platform = navigator.platform.toLocaleLowerCase()
  const isWin = sUserAgent.indexOf('windows') > -1
  const isMac = sUserAgent.indexOf('mac') > -1
  if (isWin) return 'Windows'
  if (isMac) return 'Mac'
  const isUnix = platform === 'x11' && !isWin && !isMac
  if (isUnix) return 'Unix'
  const isLinux = String(platform).indexOf('linux') > -1
  if (isLinux) return 'Linux'
  if (sUserAgent.indexOf('android') > -1) return 'Android'
  if (sUserAgent.indexOf('iphone') > -1) return 'iPhone'
  if (sUserAgent.indexOf('symbianos') > -1) return 'SymbianOS'
  if (sUserAgent.indexOf('windows phone') > -1) return 'Windows Phone'
  if (sUserAgent.indexOf('ipad') > -1) return 'iPad'
  if (sUserAgent.indexOf('ipod') > -1) return 'iPod'
  return 'others'
}

const getVersion = (userAgent, reg) => {
  const reBrowser = new RegExp(reg)
  reBrowser.test(userAgent)
  return parseFloat(RegExp['$1'])
}

// 输入框只能输入数字替换
export function handleFormatInput(value) {
  if (!value) {
    return
  }
  value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
  value = value.replace(/^\./g, '') // 验证第一个字符是数字而不是.
  value = value.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的.
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  // 输入替换，如输入05，直接替换为5，防止出现01，02这种情况
  if (value.indexOf('.') < 0 && value != '') {
    return parseFloat(value).toString()
  }
  return value
}

// 持仓列表--数量输入计算对应折合币种
export const filterNewPrice = (priceList, temp, isScoket = true) => {
  if (priceList && priceList.length > 0) {
    for (const item of priceList) {
      const tempCoin = isScoket ? item[0] : item.coin
      const tempPrice = isScoket ? item[2] : item.new_price
      if (temp.coin == tempCoin && temp.calcQuantity && temp.multiplier) {
        const tempAmt = Decimal.mul(temp.calcQuantity, temp.multiplier).div(tempPrice).toNumber()
        temp.calcCoin = Decimal(tempAmt).toFixed(temp.amountdigit, 0)
        return
      }
    }
  } else {
    temp.calcCoin = getNum(0, temp.amountdigit)
  }
}
