/* eslint-disable no-shadow */
import User from '@/api/user'
import { records } from '@/utils/country'
import {
  getToken,
  setToken,
  removeToken,
  getAccount,
  setAccount,
  removeAccount,
  getCurrency,
  setCurrency,
  removeCurrency,
  getLanguage,
  setLanguage
} from '@/utils/auth'

const handleData = data => {
  if (Array(data) && data.length > 0) {
    return data.map(item => {
      return {
        id: item.id,
        country: item.namezh,
        area_code: item.countryphonecode,
        img: item.countryicon,
        country_en: item.nameen,
        internat_abbrreviation: item.internatabbrreviation
      }
    })
  }
  return []
}
const handleRateList = data => {
  if (data && data.length > 0) {
    return data.map(item => {
      return {
        tocoin: item.tocoin,
        rate: item.rate,
        ratesymbol: item.ratesymbol
      }
    })
  }
  return []
}

const handleLangObj = data => {
  const tempObj = {}
  data.forEach(element => {
    if (!tempObj.hasOwnProperty(element.value)) {
      tempObj[element.value] = element.name
    }
  })
  return tempObj
}

const getDefaultState = () => ({
  token: getToken(),
  name: getAccount(),
  language: getLanguage() || 'zh-CN',
  currency: getCurrency() ? JSON.parse(getCurrency()).tocoin : '',
  country_list: [],
  user_info: {},
  avatar_list: [],
  rate_data: getCurrency() ? JSON.parse(getCurrency()) : {},
  rate_list: [],
  languageList: [
    {
      name: '中文简体',
      value: 'zh-CN'
    },
    {
      name: '中文繁体',
      value: 'zh-HK'
    },
    {
      name: 'English',
      value: 'en'
    }
  ],
  langObj: {}
})

const state = getDefaultState()

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_NAME(state, name) {
    state.name = name
  },
  setLanguage(state, language) {
    state.language = language
    setLanguage(language)
  },
  setLangObj(state) {
    state.langObj = handleLangObj(state.languageList)
  },
  setCurrency(state, data) {
    state.currency = data.tocoin
  },
  setCountry(state, data) {
    state.country_list = handleData(data).sort((a, b) => {
      return a['country'].localeCompare(b['country'])
    })
  },
  setUserInfo(state, data) {
    state.user_info = data
    // state.user_info = {
    //   account_pass: 'r****s@gmail.com',
    //   area_phone_code: '',
    //   email_addr: 'rachtreamotires@gmail.com',
    //   email_pass: 'r****s@gmail.com',
    //   face_unlock: 0,
    //   fp_unlock: 0,
    //   auth_level: 3,
    //   idcard_state: 3,
    //   invite_code: '',
    //   is_email_auth: 1,
    //   is_google_auth: 0,
    //   is_phone_auth: 0,
    //   is_set_tran_pwd: 0,
    //   nick_name: 'bn103252',
    //   phone_number: '',
    //   phone_pass: '',
    //   recommend_total_num: 14,
    //   user_account: 'rachtreamotires@gmail.com',
    //   user_address: '',
    //   user_id: 108,
    //   user_image: ''
    // }
  },
  setAvatar(state, data) {
    const arr = data.map((item, index) => {
      const temp = { ...item }
      temp.id = index
      return temp
    })
    state.avatar_list = [...arr]
  },
  setRateCountry(state, data) {
    state.rate_data = data
  },
  setRateList(state, data) {
    state.rate_list = handleRateList(data)
  }
}

const actions = {
  async getRateCountry({ commit }) {
    try {
      const res = await User.getRateCountryApi()
      commit('setRateCountry', res.data)
      commit('setCurrency', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setRateCountry({ dispatch }, params) {
    try {
      if (getToken()) {
        await User.setRateCountryApi({ ratecountry: params.tocoin })
        dispatch('getRateCountry')
      }
      setCurrency(JSON.stringify(params))
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getRateList({ commit }) {
    try {
      const res = await User.getRateApi()
      commit('setRateList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取国家列表
  async getCountry({ commit }) {
    try {
      // const res = await User.getCountryApi()
      // commit('setCountry', res.data)
      commit('setCountry', records)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取二维码秘钥
  getLoginQRCode() {
    return new Promise((resolve, reject) => {
      User.getLoginQRCodeApi().then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 检测当前登录状态
  async checkUserLogin({ commit }, params) {
    try {
      const res = await User.checkUserLoginApi(params)
      const { data } = res
      if (data && data.status == 2) {
        commit('SET_TOKEN', data.token)
        setToken(data.token)
      }
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 用户登录
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      User.loginApi(userInfo).then((response) => {
        const { data } = response
        if (data.jwt_token) {
          commit('SET_TOKEN', data.jwt_token)
          commit('SET_NAME', data.user_account)
          setToken(data.jwt_token)
          setAccount(data.user_account)
        }
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 退出登录
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        removeToken()
        removeAccount()
        removeCurrency()
        commit('RESET_STATE')
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  // 用户注册
  register({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      User.registerApi(userInfo).then((response) => {
        const { data } = response
        if (data.jwt_token) {
          commit('SET_TOKEN', data.jwt_token)
          setToken(data.jwt_token)
          console.log(data.jwt_token)
        }
        resolve(data.jwt_token)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 忘记密码
  forget(no, userInfo) {
    return new Promise((resolve, reject) => {
      User.forgetApi(userInfo).then((response) => {
        // const { data } = response
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 发送验证码
  sendCode(no, userInfo) {
    return new Promise((resolve, reject) => {
      User.sendCodeApi(userInfo).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 校验验证码
  checkCode(no, userInfo) {
    return new Promise((resolve, reject) => {
      User.checkCodeApi(userInfo).then((response) => {
        const { data } = response
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 获取个人信息
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      User.getUserInfoApi().then((response) => {
        if (!response) return
        const { data } = response
        commit('setUserInfo', data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 更新个人信息
  updateUserInfo(no, params) {
    return new Promise((resolve, reject) => {
      User.updateUserInfoApi(params).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 获取内置头像列表
  getAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      User.getAvatarApi().then((response) => {
        if (!response) return
        const { data } = response
        commit('setAvatar', data)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 更新密码
  editPassword(no, params) {
    return new Promise((resolve, reject) => {
      User.editPasswordApi(params).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 手机 || 邮箱 || 谷歌验证码 绑定、更改和关闭
  operateAuth(no, params) {
    return new Promise((resolve, reject) => {
      User.operateAuthApi(params).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 获取谷歌密钥
  getGoogleSecret(no, params) {
    return new Promise((resolve, reject) => {
      User.getGoogleSecretApi(params).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
