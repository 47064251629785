import request from '@/utils/request'
export default {
  // 获取最新成交
  getNewdealListApi(data) {
    return request({
      url: `/market/newdeal2?coin=${data.coin}&currency=${data.currency}`,
      method: 'get'
    })
  },
  // 获取买卖
  getFivelistApi(data) {
    return request({
      url: `/market/fivelistnew?coin=${data.coin}&currency=${data.currency}`,
      method: 'get'
    })
  },
  // 获取tickers,不带分页
  getTickersApi(data) {
    return request({
      url: 'market/tickers_no_page',
      method: 'get'
    })
  },
  // 获取k线数据
  getKlinelistApi(data) {
    return request({
      url: `market/klinenew?coin=${data.coin}&currency=${data.currency}&kline_type=${data.kline_type}`,
      method: 'get'
    })
  },
  // 获取交易对资产
  getsymbolassetApi(data) {
    return request({
      url: `account/getsymbolasset?coin=${data.coin}&currency=${data.currency}`,
      method: 'get'
    })
  },
  // 获取交易对信息
  getTickerApi(data) {
    return request({
      url: `market/ticker?coin=${data.coin}&currency=${data.currency}`,
      method: 'get'
    })
  },
  // 下单
  tradeOrderApi(data) {
    return request({
      url: `/trade/order`,
      method: 'post',
      data
    })
  },
  // 取消所有订单
  cancelAllOrderApi() {
    return request({
      url: `/trade/cancelbatchorders`,
      method: 'post'
    })
  },
  // 获取交易对24小时行情信息
  getTickerDayApi(data) {
    return request({
      url: `/market/ticker24?coin=${data.coin}&currency=${data.currency}`,
      method: 'get'
    })
  }
}
