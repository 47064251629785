import Order from '@/api/delivery/order'
import { getDate, getNum, thousandths, filterNewPrice } from '@/utils'
import { getDeliveryUnit, setDeliveryUnit } from '@/utils/auth'
import Decimal from 'decimal.js'
import store from '@/store'
import i18n from '@/i18n'
import router from '@/router'

// 更新标记价 -- 处理持仓列表
const handleList = (data) => {
  if (data.list && data.list.length > 0) {
    const allMargin = store.state.deliveryTrade.allMargin
    return data.list.map((item) => {
      const temp = { ...item }
      // 保证金模式
      temp.marginMode = {
        isCross: temp.margin_type === 2, // 全仓
        isIsolated: temp.margin_type === 1 // 逐仓
      }
      if (data.markList) {
        const symbol = data.markList.symbol
        if (temp.coin === symbol) {
          temp.tempMarkPrice = data.markList.data[0]
        }
      }
      const tempEP =
        temp.entry_price && Number(temp.entry_price) > 0 ? Decimal.div(1, temp.entry_price) : 0
      const tempMP =
        temp.tempMarkPrice && Number(temp.tempMarkPrice) > 0
          ? Decimal.div(1, temp.tempMarkPrice)
          : 0
      // console.log(tempEP.toString(), tempMP.toString())
      // 未实现盈亏
      temp.calcPnl = Decimal.mul(temp.position_amt, temp.multiplier)
        .mul(temp.orderDirection)
        .mul(Decimal.sub(tempEP, tempMP))
        .toNumber()

      // 仓位价值
      // const tempPrice = Decimal.mul(temp.absSize, temp.entry_price).toNumber()

      // 名义价值
      temp.notional = Decimal.mul(temp.absSize, temp.multiplier)
        .div(temp.tempMarkPrice)
        .toNumber()

      if (temp.margin_type == 1) {
        // 逐仓 -- 初始价值
        temp.initial = Decimal.mul(temp.absSize, temp.multiplier)
          .div(temp.entry_price)
          .toNumber()
      }

      if (allMargin && allMargin.length > 0) {
        allMargin.forEach((el) => {
          if (
            temp.coin_id === el.coin_id &&
            temp.notional > el.minval &&
            (temp.notional < el.maxval || el.maxval === 0)
          ) {
            // console.log(el, temp.notional)
            // 仓位维持保证金
            temp.maintain = Decimal.mul(temp.notional, el.margin_rate).sub(el.amount).toNumber()
          }
        })
      }

      // 仓位初始保证金
      if (temp.margin_type === 2) {
        // 全仓 -- 初始保证金
        temp.isolated_margin = Decimal.mul(temp.notional, temp.imr).toString()
      }

      // 回报率
      temp.calcRoe =
        temp.isolated_margin && Number(temp.isolated_margin) > 0
          ? Decimal.div(temp.calcPnl, temp.isolated_margin).toString()
          : 0

      temp.positionMargin = Decimal(temp.isolated_margin).toFixed(temp.amountdigit, Decimal.ROUND_DOWN)
      const tempPnl = temp.calcPnl ? thousandths(Decimal(temp.calcPnl).toFixed(temp.amountdigit, Decimal.ROUND_DOWN)) : ''
      temp.positionPnl = temp.calcPnl > 0 ? `+${tempPnl}` : tempPnl
      const tempRoe = Decimal.mul(temp.calcRoe, 100).toNumber().toFixed(2)
      temp.roeRate =
        temp.calcRoe > 0
          ? `+${tempRoe}%`
          : `${tempRoe}%`
      temp.markPrice = temp.tempMarkPrice
      return temp
    })
  }
}

// 处理持仓列表初始数据
const handleInitData = (data) => {
  if (data && data.length) {
    return data.map((item) => {
      const temp = { ...item }
      // 持仓数量绝对值
      temp.absSize = Decimal.abs(temp.position_amt).toNumber()
      temp.inputQuantity = getNum(temp.absSize, 0)
      temp.inputPrice = item.mark_price
      temp.calcQuantity = temp.inputQuantity
      const isNew = state.newPrice && state.newPrice.length > 0
      temp.tempMarkPrice = item.mark_price
      temp.markPrice = temp.tempMarkPrice
      temp.liquidation_price =
        item.liquidation_price
          ? Number(item.liquidation_price) < 0
            ? '--'
            : thousandths(Decimal(item.liquidation_price).toFixed(item.pricedigit, Decimal.ROUND_DOWN))
          : '--'
      temp.liquidationPrice = temp.liquidation_price
      temp.calcPnl = item.unrealized_profit
      temp.calcRoe = item.roe_rate.replace('%', '')
      temp.isEnter = false
      temp.tableInputError = false
      temp.calcCoin = 0
      filterNewPrice(
        isNew ? state.newPrice : store.state.deliveryTrade.tickersList,
        temp,
        isNew
      ) // 张数折合币种数量

      // 订单方向
      temp.orderDirection =
        temp.position_side !== 'both'
          ? temp.position_side === 'short'
            ? -1
            : 1
          : temp.position_amt >= 0
            ? 1
            : -1
      // 持仓模式
      temp.positionMode = {
        isLong: temp.orderDirection == 1 && temp.position_side !== 'both', // 双向持仓 --- 开多
        isShort: temp.orderDirection == -1 && temp.position_side !== 'both', // 双向持仓 ---  开空
        isSingle: temp.position_side === 'both', // 单向持仓
        isBuy: temp.position_side === 'both' && temp.position_amt > 0, // 单向持仓 --- 买
        isSell: temp.position_side === 'both' && temp.position_amt < 0 // 单向持仓 --- 卖
      }

      // 名义价值
      temp.notional = Decimal.mul(temp.absSize, temp.multiplier)
        .div(temp.tempMarkPrice)
        .toNumber()

      // 杠杆倍率
      temp.imr = temp.leverage && temp.leverage > 0 ? Decimal.div(1, temp.leverage).toFixed(4, Decimal.ROUND_UP) : 0
      if (state.amountUnit.value == 1) {
        const newP = store.state.deliveryTrade.tickersInfo.new_price
        if (newP) {
          const tempAmount = Decimal.mul(item.position_amt, item.multiplier).div(newP).toNumber()
          temp.positionAmt = `${Decimal(tempAmount).toFixed(item.amountdigit, 0)} ${item.coin}`
        }
      } else {
        temp.positionAmt = `${temp.position_amt} ${i18n.t('message.delivery.sheet')}`
      }
      // 仓位初始保证金
      if (temp.margin_type === 2) {
        // 全仓 -- 初始保证金
        temp.positionMargin = Decimal.mul(temp.notional, temp.imr)
        temp.positionMargin = temp.positionMargin.toFixed(temp.amountdigit, Decimal.ROUND_DOWN)
      } else {
        temp.positionMargin = getNum(temp.isolated_margin, temp.amountdigit)
        const coin = router.history.current.params.tradePair.split('U')[0]
        if (coin == item.coin && temp.positionMode.isSingle) {
          state.assetsData.isolated = Decimal.sub(
            item.isolated_margin,
            Decimal.mul(item.position_amt, item.multiplier).mul(temp.imr).div(item.entry_price)
          ).toString()
        }
      }
      if (!state.closeSize.hasOwnProperty(item.coin)) {
        state.closeSize[item.coin] = {
          long: '',
          short: ''
        }
      }
      if (item.position_side === 'short') {
        state.closeSize[item.coin].long = temp.position_amt
      }
      if (item.position_side === 'long') {
        state.closeSize[item.coin].short = temp.position_amt
      }
      return temp
    })
  } else {
    return []
  }
}

// 切换杠杆更新到持仓列表
const handleLeverage = ({ list, data }) => {
  if (list && list.length > 0) {
    return list.map((item) => {
      if (item.coin === data.coin) {
        item.leverage = data.leverage
        // 杠杆倍率
        item.imr = item.leverage && item.leverage > 0 ? Decimal.div(1, item.leverage).toFixed(4, Decimal.ROUND_UP) : 0
        item.positionMargin = Decimal.mul(item.notional, item.imr).toFixed(item.amountdigit, Decimal.ROUND_DOWN)
      }
      return item
    })
  }
  return list
}

// 切换盘口价格更新持仓列表
const handleCapPrice = ({ list, data }) => {
  if (list && list.length > 0) {
    return list.map((item) => {
      if (item.coin === data.coin) {
        item.inputPrice = data.price
      }
      return item
    })
  }
  return list
}

// 切换数量单位更新持仓数量
const changeCoinAmt = (list) => {
  if (list && list.length) {
    return list.map((item) => {
      // const
      if (state.amountUnit.value == 1) {
        if (state.newPrice.length > 0) {
          state.newPrice.forEach((el) => {
            if (el[0] == item.coin) {
              const tempAmt = Decimal.mul(item.position_amt, item.multiplier).div(el[2]).toNumber()
              item.positionAmt = `${Decimal(tempAmt).toFixed(item.amountdigit, 0)} ${item.coin}`
            }
          })
        } else {
          const coin = router.history.current.params.tradePair.split('U')[0]
          const newP = coin == item.coin ? store.state.deliveryTrade.tickersInfo.new_price : item.markPrice
          const tempAmt = Decimal.mul(item.position_amt, item.multiplier).div(newP).toNumber()
          item.positionAmt = `${Decimal(tempAmt).toFixed(item.amountdigit, 0)} ${item.coin}`
        }
      } else {
        item.positionAmt = `${item.position_amt} ${i18n.t('message.delivery.sheet')}`
      }
      return item
    })
  }
  return list
}

// 计算合约资产相关
const handleAssetsData = (assetsInfo) => {
  const digit = store.state.deliveryTrade.tickersInfo.amount_digit || 4
  const temp = { ...state.assetsData }
  temp.amount = getNum(assetsInfo.balance, digit) // 余额
  temp.usableMargin = getNum(assetsInfo.available_balance, digit) // 可用保证金
  const coin = router.history.current.params.tradePair ? router.history.current.params.tradePair.split('U')[0] : ''
  const list = state.futPositonList
  let maintain = 0
  let pnl = 0
  let initMargin = 0
  temp.marginType = assetsInfo.margin_type
  temp.positionSide = assetsInfo.position_type
  let tempImr = null
  temp.hedgeSize = 0

  // 1 -- 单向持仓  2 -- 双向持仓
  const positionMode = store.state.deliveryTrade.futInitVal && store.state.deliveryTrade.futInitVal.position_type == 1
  temp.marginRateList = {}
  if (list && list.length) {
    list.forEach((item) => {
      if (coin == item.coin) {
        // 判断仓位币种是否是当前交易对页面币种
        const tempPnl = item.calcPnl || 0
        pnl = Decimal.add(tempPnl, pnl).toNumber() // 所有同币种仓位未实现盈亏之和

        const tempMargin = item.isolated_margin || 0
        initMargin = Decimal.add(tempMargin, initMargin).toNumber() // 所有同币种仓位初始保证金之和

        const tempMaintain = item.maintain || 0
        maintain = Decimal.add(tempMaintain, maintain).toNumber() // 所有同币种仓位维持保证金之和

        temp.notional = Decimal.mul(temp.position_amt, temp.multiplier)
          .div(temp.tempMarkPrice)
          .toNumber()
        temp.initial = item.initial
        tempImr = item.imr
        if (!item.positionMode.isSingle) {
          // 双向持仓
          temp.hedgeSize = Decimal.add(temp.hedgeSize, item.position_amt).toString()
        } else {
          // 单向持仓
          item.orderDirection == -1
            ? (temp.bothSize.sell = Decimal.abs(item.position_amt).toString())
            : (temp.bothSize.buy = Decimal.abs(item.position_amt).toString())
        }
        if (item.margin_type == 1 && item.position_side != 'both') {
          const positionMode = item.position_side == 'long' ? '做多' : item.position_side == 'short' ? '做空' : ''
          const tempMargin = Decimal.add(item.isolated_margin, item.calcPnl).toNumber() || 0
          const tempRate = item.maintain && tempMargin ? Decimal.div(item.maintain, tempMargin).mul(100).toNumber() : 0
          temp.marginRateList[`${item.coin}USD 逐仓/${positionMode}`] = {
            margin: getNum(tempMargin, item.amountdigit),
            maintain: item.maintain ? getNum(item.maintain, item.amountdigit) : '0.0000',
            marginRate: tempRate != 0 ? tempRate.toFixed(2) : '0.00'
          }
        }
      }
    })
  }
  if (temp.positionSide == 1 || temp.marginType === 2) {
    // 单向全逐 || 双向全仓
    if (temp.marginType === 2) {
      temp.margin =
      assetsInfo.balance && pnl
        ? Decimal.add(assetsInfo.balance, pnl).toNumber()
        : assetsInfo.balance
    } else {
      temp.margin =
      initMargin && pnl
        ? Decimal.add(initMargin, pnl).toNumber()
        : assetsInfo.balance
    }
    temp.maintain = maintain ? getNum(maintain, digit) : '0.0000'
    temp.margin = temp.margin && digit ? getNum(temp.margin, digit) : '0.0000'
    temp.marginRate =
    temp.margin && Number(temp.margin) > 0
      ? Decimal.div(temp.maintain, temp.margin).mul(100).toNumber()
      : 0
    temp.marginRate = temp.marginRate != 0 ? temp.marginRate.toFixed(2) : '0.00'
  } else {
    // console.log(temp.marginRateList)
  }
  temp.pnl = pnl ? getNum(pnl, digit) : '0.0000'

  const tempBuy = assetsInfo.both_buy_vul || 0
  const tempSell = assetsInfo.both_sell_vul || 0
  if (positionMode) {
    // 单向持仓
    if (temp.marginType === 2) {
      // 全仓---可用下单余额
      // 合约占用初始保证金
      temp.occupyInitMargin =
        temp.notional && tempImr
          ? Decimal.max(
            Decimal.abs(Decimal.add(temp.notional, tempBuy)),
            Decimal.abs(Decimal.sub(temp.notional, tempSell))
          )
            .mul(tempImr)
            .toString()
          : 0
      temp.usableMargin = Decimal.max(
        0,
        Decimal.add(assetsInfo.balance, pnl).sub(temp.occupyInitMargin)
      ).toNumber()
      temp.usableMargin = Decimal.max(0, Decimal.add(assetsInfo.balance, pnl)).toNumber()
    } else if (temp.marginType === 1) {
      // 逐仓---可用下单余额
      // 合约占用初始保证金
      // temp.occupyInitMargin =
      //   temp.initial && tempImr
      //     ? Decimal.max(
      //       Decimal.abs(Decimal.add(temp.notional, tempBuy)),
      //       Decimal.abs(Decimal.sub(temp.notional, tempSell)))
      //       .mul(tempImr)
      //       .toString()
      //     : 0
      // // 合约占用保证金
      // const occupyMargin = Decimal.add(temp.occupyInitMargin, temp.isolated)
      // temp.usableMargin = Decimal.max(0, Decimal.sub(assetsInfo.balance, occupyMargin)).toNumber()
      temp.usableMargin = assetsInfo.available_balance
    }
  } else {
    // 双向持仓
    if (temp.marginType === 2) {
      // 全仓---可用下单余额
      temp.usableMargin = Decimal.max(
        0,
        Decimal.add(assetsInfo.balance, pnl).sub(initMargin)
      ).toNumber()
    } else if (temp.marginType === 1) {
      // 逐仓---可用下单余额
      // temp.usableMargin = Decimal.max(
      //   0,
      //   Decimal.sub(assetsInfo.balance, initMargin)
      // ).toNumber()
      temp.usableMargin = assetsInfo.available_balance
    }
  }
  return temp
}

// 订单列表
const handleTableList = (data) => {
  state.openSize.buy = 0
  state.openSize.sell = 0
  if (data && data.length > 0) {
    return data.map((item) => {
      const temp = { ...item }
      temp.create_time = item.create_time
        ? getDate(Number(`${item.create_time.toString()}`), 'YYYY-MM-DD HH:mm:ss')
        : ''
      temp.update_time = item.update_time
        ? getDate(Number(`${item.update_time.toString()}`), 'YYYY-MM-DD HH:mm:ss')
        : ''
      if (item.buy_type === 1) {
        // 买入
        temp.orderDirectionText =
          temp.position_side === 'both'
            ? item.position_type
            : `${i18n.t('message.futures.buy')}${item.position_type}`
        state.openSize.buy = Decimal.add(state.openSize.buy, temp.num).toString()
      }
      if (item.buy_type === 2) {
        // 卖出
        temp.orderDirectionText =
          temp.position_side === 'both'
            ? item.position_type
            : `${i18n.t('message.futures.sell')}${item.position_type}`
        state.openSize.sell = Decimal.add(state.openSize.sell, temp.num).toString()
      }
      const tempSize = temp.buy_type == 1 ? temp.num : temp.num * -1
      const mul = store.state.deliveryTrade.tickersInfo.multiplier
      if (mul) {
        temp.pendingOrderPrice =
          tempSize && temp.price ? Decimal.mul(tempSize, mul).div(temp.price).toNumber() : 0
      }
      return temp
    })
  }
  return []
}
// 订单详情
const handleOrderInfo = (data) => {
  if (Object.keys(data).length > 0) {
    const temp = { ...data }
    if (temp.deal_list && temp.deal_list.length) {
      temp.deal_list = temp.deal_list.map((item) => {
        const tempItem = { ...item }
        tempItem.coin = data.coin
        tempItem.create_date = getDate(
          Number(`${item.create_date.toString()}`),
          'YYYY-MM-DD HH:mm:ss'
        )
        tempItem.amount = item.num_coin
        tempItem.currency = data.currency
        tempItem.poundage = `${getNum(item.poundage || 0, 8)} ${data.coin}`
        return tempItem
      })
    } else {
      temp.deal_list = []
    }
    return temp
  }
}

const getDefaultState = () => {
  return {
    futPositonList: [],
    tradeOrderList: {
      list: [],
      total: 0
    },
    historyOrderList: {
      list: [],
      total: 0
    },
    recordOrderList: {
      list: [],
      total: 0
    },
    assetsOrderList: {
      list: [],
      total: 0
    },
    pendingOrder: 0, // 挂单数量
    orderInfo: {},
    listCount: {
      position: 0,
      entrust: 0
    },
    assetsData: {
      marginType: 1, // 保证金模式
      positionSide: 1, // 持仓模式
      amount: '0.0000', // 余额
      pnl: '0.0000', // 未实现盈亏
      maintain: '0.0000', // 维持保证金
      margin: '0.0000', // 保证金余额
      marginRate: '0.00', // 保证金率
      usableMargin: 0, // 可用保证金
      hedgeSize: 0, // 双向持仓数量
      bothSize: {
        buy: 0, // 单向持仓数量 -- 做多
        sell: 0 // 单向持仓数量 -- 做空
      }, // 单向持仓数量
      isolated: 0, // 逐仓计算结果数据--用于计算单向持仓开仓成本--具体公式为：逐仓保证金-逐仓持仓数量(张)*合约乘数*IMR/开仓价格
      notional: 0, // 仓位名义价值 -- 用于计算单向持仓开仓成本
      occupyInitMargin: 0, // 合约占用初始保证金 -- 用于计算单向持仓开仓成本
      marginRateList: {} // 保证金比率相关信息
    },
    markPrice: {},
    newPrice: [],
    amountUnit: getDeliveryUnit() ? JSON.parse(getDeliveryUnit()) : { label: '', value: 1 },
    capPrice: null,
    closeSize: {},
    openSize: {
      buy: 0, // 挂单数量 -- 买入
      sell: 0 // 挂单数量 -- 卖出
    }
  }
}
const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  // 初始化持仓列表
  setFutPList(state, data) {
    state.closeSize = {}
    state.futPositonList = handleInitData(data)
  },
  // 根据推送数据更新持仓列表
  updateFutList(state, data) {
    const assetsInfo = store.state.deliveryTrade.futInitVal
    state.futPositonList = handleList(data)
    state.assetsData = handleAssetsData(assetsInfo)
  },
  // 更新资产信息
  updateAssetsData(state, assetsInfo) {
    state.assetsData = handleAssetsData(assetsInfo)
  },
  // 更新持仓列表杠杆倍数
  updateLeverage(state, data) {
    state.futPositonList = handleLeverage({ list: state.futPositonList, data })
  },
  // 更新标记价
  updateMarkPrice(state, data) {
    state.markPrice = data
  },
  // 更新最新价
  updateNewPrice(state, data) {
    state.newPrice = data
    state.futPositonList = changeCoinAmt(state.futPositonList)
  },
  // 填充盘口价格
  updateCapPrice(state, data) {
    state.capPrice = data.price
    state.futPositonList = handleCapPrice({ list: state.futPositonList, data })
  },
  // 更新数量单位
  updateAmountUnit(state, data) {
    state.amountUnit = data
    setDeliveryUnit(JSON.stringify(data))
    state.futPositonList = changeCoinAmt(state.futPositonList)
  },
  // 订单列表
  setOrderList(state, { type, data }) {
    if (type === 1) {
      state.pendingOrder = data.data_total
      state.tradeOrderList.list = handleTableList(data.data_list)
      state.tradeOrderList.total = data.data_total || 0
    } else if (type === 2) {
      state.historyOrderList.list = handleTableList(data.data_list)
      state.historyOrderList.total = data.data_total || 0
    } else if (type === 3) {
      state.recordOrderList.list = handleTableList(data.data_list)
      state.recordOrderList.total = data.data_total || 0
    } else if (type === 4) {
      state.assetsOrderList.list = handleTableList(data.data_list)
      state.assetsOrderList.total = data.data_total || 0
    }
  },
  setOrderInfo(state, data) {
    state.orderInfo = handleOrderInfo(data)
  }
}

const actions = {
  // 合约持有仓位列表
  async getFutPList({ commit }, params) {
    try {
      const res = await Order.getFutPListApi(params)
      commit('setFutPList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取订单列表
  async getOrderList({ commit }, params) {
    try {
      const res = await Order.getOrderListApi(params)
      commit('setOrderList', { type: params.type, data: res.data })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取订单详情
  async getOrderInfo({ commit }, data) {
    try {
      const res = await Order.getOrderInfoApi(data)
      commit('setOrderInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 撤单
  async cancelOrder({ commit }, data) {
    try {
      await Order.cancelOrderApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 批量撤单
  async cancelbatchorders() {
    try {
      await Order.cancelbatchordersApi()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 一键平仓
  async closeAll() {
    try {
      await Order.closeAllApi()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 调整保证金
  async adjustMargin(no, params) {
    try {
      await Order.adjustMarginApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 逐仓调整保证金计算强平价
  async calcPrice(no, params) {
    try {
      const res = await Order.calcPriceApi(params)
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 持仓分享
  async toShare(no, params) {
    try {
      const res = await Order.shareApi(params)
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
