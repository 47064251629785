import request from '@/utils/request'
export default {
  // 获取用户自选
  getAccountLikeApi() {
    return request({
      url: '/account/like',
      method: 'get'
    })
  },
  // 编辑用户自选
  updateLikeApi(data) {
    return request({
      url: `/account/updatelike`,
      method: 'post',
      data
    })
  },
  // 新增用户自选
  addLikeApi(data) {
    return request({
      url: `/account/like`,
      method: 'post',
      data
    })
  },
  // 获取所有交易区 -- 现货
  getCurrencyGroupApi() {
    return request({
      url: `/market/currencygroup`,
      method: 'get'
    })
  },
  // 根据单个交易区获取所有币种 -- 现货
  getCurrencyTickersApi(currency) {
    return request({
      url: `/market/currencytickers?currency=${currency}`,
      method: 'get'
    })
  },
  // 获取所有交易区 -- 现货
  getTickersApi(data) {
    return request({
      url: `/market/tickers`,
      method: 'get',
      params: data && Object.keys(data).length > 0 ? {
        symbol: data.symbol,
        currency: data.currency,
        plate: data.plate,
        page: data.page,
        size: data.size
      } : {}
    })
  }
}
