export default {

  install(Vue) {
    Vue.directive('focus', {
      inserted: function(el) {
        if (el.nodeName == 'DIV') {
          el.children[0].firstElementChild.addEventListener('focus', function() {
            el.style.borderColor = '#FFC304'
          })
          el.children[0].firstElementChild.addEventListener('blur', function() {
            el.style.borderColor = '#b7bdc6'
          })
        }
      }
    })
  }
}
