import request from '@/utils/request'

export default {
  // 获取所有交易对信息
  getTickerListApi(params) {
    return request({
      url: '/futures/market/tickers',
      method: 'get',
      params
    })
  },
  // 获取比本位所有交易对信息
  getTickerNotUListApi(params) {
    return request({
      url: '/market/tickers',
      method: 'get',
      params,
      headers: { model_type: 'DAPI' }
    })
  },
  // 获取单个合约币信息
  getTickerInfoApi(data) {
    return request({
      url: '/futures/market/ticker',
      method: 'get',
      params: data
    })
  },
  // 获取当前币种杠杆信息
  getTradeLevelApi(id) {
    return request({
      url: `/futures/calc/tradelevel/${id}`,
      method: 'get'
    })
  },
  // 计算收益 || 强平价格
  toCalcApi(data) {
    const url = `/futures/calc/${data.type}`
    delete data.type
    return request({
      url,
      method: 'post',
      data
    })
  },
  // 获取u本位资产统计
  getAssetsInfoApi() {
    return request({
      url: '/futures/getfutholdassets',
      method: 'get'
    })
  },
  // 获取u本位资产统计 新接口
  getAssetNewInfoApi() {
    return request({
      url: '/futures/getfutholdall',
      method: 'get'
    })
  },
  // 获取币本位资产统计
  getAssetsNotUInfoApi() {
    return request({
      url: '/asset/getholdassets',
      method: 'get',
      headers: { model_type: 'DAPI' }
    })
  },
  // 获取杠杆与保证金设置
  getAllMarginApi() {
    return request({
      url: '/futures/getallmarginset',
      method: 'get'
    })
  },
  // 合约账户详情
  getFutHoldDetailApi(data) {
    return request({
      url: '/futures/getfutholddetail',
      method: 'post',
      data
    })
  },
  // 获取划转账户详情
  getTransDetailApi(data) {
    return request({
      url: '/futures/transferaccount',
      method: 'post',
      data
    })
  },
  // 划转
  toTransApi(data) {
    return request({
      url: '/futures/transfer',
      method: 'post',
      data
    })
  },
  // 合约获取用户自选
  getMarketLikeApi() {
    return request({
      url: '/futures/market/like',
      method: 'get'
    })
  },
  // 搜索合约交易对
  searchTickerApi(data) {
    return request({
      url: `/asset/searchticker`,
      // url: `/futures/market/searchticker`,
      method: 'get',
      params: data,
      headers: { model_type: 'DAPI' }
    })
  },
  // 合约--下单
  placeOrderApi(data) {
    return request({
      url: `/futures/trade/order`,
      method: 'post',
      data
    })
  },
  // 获取合约交易页面初始值（仓位模式、杠杆）
  getTradeSetApi(params) {
    return request({
      url: '/futures/user/tradeset',
      method: 'get',
      params
    })
  },
  // 获取合约买卖5订单
  getFivelistApi(data) {
    return request({
      url: `/futures/market/fivelistnew?coin=${data.coin}`,
      method: 'get'
    })
  },
  // 获取最新成交
  getNewdealListApi(data) {
    return request({
      url: `/futures/market/newdeal2?coin=${data.coin}`,
      method: 'get'
    })
  },

  // 新增自选_u本位和币本位
  addlikeApi(data) {
    return request({
      url: `/asset/addlike`,
      method: 'post',
      data,
      headers: { model_type: 'DAPI' }
    })
  },

  // 取消自选_u本位和币本位
  removelikeApi(data) {
    return request({
      url: `/asset/removelike`,
      method: 'post',
      data,
      headers: { model_type: 'DAPI' }
    })
  },

  // // 新增自选_新
  // addlikeApi(data) {
  //   return request({
  //     url: `/futures/market/addlike`,
  //     method: 'post',
  //     data
  //   })
  // },
  // 取消自选_新
  // removelikeApi(data) {
  //   return request({
  //     url: `/futures/market/removelike`,
  //     method: "post",
  //     data,
  //   });
  // },
  // 获取kline
  getKlineListApi(data) {
    return request({
      url: `/futures/market/klinenew?coin=${data.coin}&kline_type=${data.kline_type}`,
      method: 'get'
    })
  },
  // 保证金模式切换
  changeMarginTypeApi(data) {
    return request({
      url: '/futures/trade/marginType',
      method: 'post',
      data
    })
  },
  // 调整杠杆倍数
  updateUserLeverageApi(data) {
    return request({
      url: '/futures/updateuserleverage',
      method: 'post',
      data
    })
  },
  // 调整仓位模式
  updatePositionApi(data) {
    return request({
      url: '/futures/trade/positionSide/dual',
      method: 'post',
      data
    })
  },
  // 加载法币(otc交易币种)列表
  otcLegalCurrencyApi() {
    return request({
      url: '/futures/getotccoins',
      method: 'get'
    })
  }
}
