import Order from '@/api/futures/order'
import { getDate, getNum, thousandths } from '@/utils'
import { getFutUnit, setFutUnit } from '@/utils/auth'
import Decimal from 'decimal.js'
import store from '@/store'
import i18n from '@/i18n'
/**
 * USDT永续合约清算模型
 *
 * 合约1 = BTCUSDT-LONG + BTCUSDT-SHORT = BTCUSDT-SINGLE
 *
 * https://www.binance.com/zh-CN/support/faq/b3c689c1f50a44cabb3a84e663b81d93
 *
 * @param walletBalance 账户余额,全仓模式下为全仓钱包余额,逐仓模式下为逐仓钱包余额
 *
 * @param sumOfMaintenanceMarginExceptSelf 其它合约的维持保证金总和,如果逐仓则是0
 * @param sumOfUnrealizedPNLExceptSelf 其他合约的未实现盈亏总和,如果逐仓则是0
 *
 * @param maintenanceAmountOfSingle 单向模式下合约自身维持保证金速算额
 * @param maintenanceAmountOfLong 开多模式下合约1维持保证金速算额
 * @param maintenanceAmountOfShort 开空模式下合约1维持保证金速算额
 *
 * @param directionOfSingle 单向模式下合约1方向, 1多仓, -1空仓
 *
 * @param sizeOfSingle 单向模式下合约1持仓数量,无论多空取绝对值
 * @param entryPriceOfSingle 单向模式下合约1开仓价格
 *
 * @param sizeOfLong 双向模式下合约1开多持仓数量,无论多空取绝对值,单向模式下为0
 * @param entryPriceOfLong 双向模式下合约1开多开仓价格,无论多空取绝对值
 *
 * @param sizeOfShort 双向模式下合约1开空持仓数量,无论多空取绝对值,单向模式下为0
 * @param entryPriceOfShort 双向模式下合约1开空开仓价格,无论多空取绝对值
 *
 * @param maintenanceMarginRateOfSingle 单向模式下合约1维持保证金费率
 * @param maintenanceMarginRateOfLong 双向模式下合约1开多维持保证金费率
 * @param maintenanceMarginRateOfShort 双向模式下合约1开空维持保证金费率
 *
 */

// 合约1：BTC合约品种
const liquidationComputation = () => {
  return {
    walletBalance: 0,
    maintenanceAmountOfSingle: 0,
    maintenanceAmountOfLong: 0,
    maintenanceAmountOfShort: 0,
    directionOfSingle: 0,
    sizeOfSingle: 0,
    entryPriceOfSingle: 0,
    sizeOfLong: 0,
    entryPriceOfLong: 0,
    sizeOfShort: 0,
    entryPriceOfShort: 0,
    maintenanceMarginRateOfSingle: 0,
    maintenanceMarginRateOfLong: 0,
    maintenanceMarginRateOfShort: 0
  }
}

const computation = liquidationComputation()

const computation1 = {
  sumOfMaintenanceMarginExceptSelf: 0,
  sumOfUnrealizedPNLExceptSelf: 0
}

// 计算全仓模式下除合约1以外维持保证金之和&未实现盈亏之和
const computeMaintenanceMarginAPNL = (list, baseId) => {
  if (list && list.length > 0) {
    computation1.sumOfMaintenanceMarginExceptSelf = 0
    computation1.sumOfUnrealizedPNLExceptSelf = 0
    list.forEach((item) => {
      if (item.marginMode.isCross && item.coin_id !== baseId) {
        // 全仓模式 && 非合约1
        computation1.sumOfMaintenanceMarginExceptSelf = item.maintain
          ? Decimal.add(computation1.sumOfMaintenanceMarginExceptSelf, item.maintain).toNumber()
          : 0
        computation1.sumOfUnrealizedPNLExceptSelf = item.calcPnl
          ? Decimal.add(computation1.sumOfUnrealizedPNLExceptSelf, item.calcPnl).toNumber()
          : 0
      }
    })
  }
}

// 强平价格计算
const computeLiquidationPrice = (temp, tempComputation) => {
  const {
    walletBalance,
    maintenanceAmountOfSingle,
    maintenanceAmountOfLong,
    maintenanceAmountOfShort,
    directionOfSingle,
    sizeOfSingle,
    entryPriceOfSingle,
    entryPriceOfLong,
    entryPriceOfShort,
    maintenanceMarginRateOfSingle,
    maintenanceMarginRateOfLong,
    maintenanceMarginRateOfShort
  } = tempComputation

  let { sizeOfLong, sizeOfShort } = tempComputation

  const { sumOfMaintenanceMarginExceptSelf, sumOfUnrealizedPNLExceptSelf } = computation1

  if (temp.marginMode.isIsolated && !temp.positionMode.isSingle) {
    // 逐仓 && 双向持仓
    if (temp.positionMode.isLong) {
      // 开多
      sizeOfShort = 0
    }
    if (temp.positionMode.isShort) {
      // 开空
      sizeOfLong = 0
    }
  }
  const d1 = Decimal.sub(walletBalance, sumOfMaintenanceMarginExceptSelf)
    .add(sumOfUnrealizedPNLExceptSelf)
    .add(maintenanceAmountOfSingle)
    .add(maintenanceAmountOfLong)
    .add(maintenanceAmountOfShort)
    .sub(Decimal.mul(directionOfSingle, sizeOfSingle).mul(entryPriceOfSingle))
    .sub(Decimal.mul(sizeOfLong, entryPriceOfLong))
    .add(Decimal.mul(sizeOfShort, entryPriceOfShort))

  const d2 = Decimal.mul(sizeOfSingle, maintenanceMarginRateOfSingle)
    .add(Decimal.mul(sizeOfLong, maintenanceMarginRateOfLong))
    .add(Decimal.mul(sizeOfShort, maintenanceMarginRateOfShort))
    .sub(Decimal.mul(directionOfSingle, sizeOfSingle))
    .sub(sizeOfLong)
    .add(sizeOfShort)
  if (d2 == 0) {
    return 0
  }
  // console.log(tempComputation)
  // console.log(sumOfMaintenanceMarginExceptSelf)
  // console.log(sumOfUnrealizedPNLExceptSelf)
  // console.log(d1.toNumber(), d2.toNumber(), Decimal.div(d1, d2).toNumber())
  Decimal.set({ precision: 20, rounding: Decimal.ROUND_DOWN })
  return Decimal.div(d1, d2).toNumber()
}

// 强平价格模型赋值
const handleAssignment = (positions, assetsInfo) => {
  Object.assign(computation, liquidationComputation())
  const tempComputation = { ...computation }
  tempComputation.walletBalance = assetsInfo.balance
  if (positions && positions.length > 0) {
    positions.forEach((temp) => {
      // const absSize = Decimal.abs(temp.position_amt).toNumber()
      if (temp.positionMode.isLong) {
        // 双向--开多
        if (temp.marginMode.isIsolated) {
          // 逐仓 -- 钱包余额 == 保证金
          tempComputation.walletBalance = temp.isolated_margin
        }
        tempComputation.sizeOfLong = temp.absSize
        tempComputation.entryPriceOfLong = temp.entry_price
        tempComputation.maintenanceAmountOfLong = temp.lm.amount || 0
        tempComputation.maintenanceMarginRateOfLong = temp.lm.margin_rate || 0
      }
      if (temp.positionMode.isShort) {
        // 双向--开空
        if (temp.marginMode.isIsolated) {
          // 逐仓 -- 钱包余额 == 保证金
          tempComputation.walletBalance = temp.isolated_margin
        }
        tempComputation.sizeOfShort = temp.absSize
        tempComputation.entryPriceOfShort = temp.entry_price
        tempComputation.maintenanceAmountOfShort = temp.lm.amount || 0
        tempComputation.maintenanceMarginRateOfShort = temp.lm.margin_rate || 0
      }
      if (temp.positionMode.isSingle) {
        // 单向
        tempComputation.directionOfSingle = Decimal.sign(temp.position_amt) || 0
        tempComputation.sizeOfSingle = temp.absSize
        tempComputation.entryPriceOfSingle = temp.entry_price
        tempComputation.maintenanceAmountOfSingle = temp.lm.amount || 0
        tempComputation.maintenanceMarginRateOfSingle = temp.lm.margin_rate || 0
      }
    })
    if (assetsInfo.flag) {
      tempComputation.walletBalance = assetsInfo.balance
    }
  }
  return tempComputation
}

// 初步处理持仓列表
const handleList = (data) => {
  if (data.list && data.list.length > 0) {
    const allMargin = store.state.futuresTrade.allMargin
    state.baseIdPositionsMap = {}
    return data.list.map((item) => {
      const temp = { ...item }
      // 保证金模式
      temp.marginMode = {
        isCross: temp.margin_type === 2, // 全仓
        isIsolated: temp.margin_type === 1 // 逐仓
      }
      temp.lm = {}
      if (data.markList) {
        const symbol = data.markList.symbol
        if (temp.coin === symbol) {
          // console.log('symbol---', symbol, data.markList.data[0])
          temp.tempMarkPrice = data.markList.data[0]
        }
      }

      // 未实现盈亏
      temp.calcPnl = Decimal.mul(temp.absSize, temp.orderDirection)
        .mul(Decimal.sub(temp.tempMarkPrice, temp.entry_price))
        .toString()

      // 仓位价值
      const tempPrice = Decimal.mul(temp.absSize, temp.entry_price).toNumber()

      // 名义价值
      const notional = Decimal.mul(temp.absSize, temp.tempMarkPrice).toNumber()
      if (allMargin && allMargin.length > 0) {
        allMargin.forEach((el) => {
          if (
            temp.coin_id === el.coin_id &&
            tempPrice > el.minval &&
            (tempPrice < el.maxval || el.maxval === 0)
          ) {
            // 仓位维持保证金
            temp.maintain = Decimal.mul(notional, el.margin_rate).sub(el.amount).toNumber()
            // 仓位维持保证金速算额&&维持保证金率等信息
            temp.lm = el
          }
        })
      }
      if (!state.baseIdPositionsMap.hasOwnProperty(temp.coin_id)) {
        state.baseIdPositionsMap[temp.coin_id] = []
      }
      state.baseIdPositionsMap[temp.coin_id].push(temp)
      return temp
    })
  }
}

// 持仓列表
const handleFutList = (data, assetsInfo, type) => {
  if (data && data.length > 0) {
    const coinIdArr = []
    const result = {}
    return data.map((item) => {
      const temp = { ...item }
      // 仓位初始保证金
      if (temp.margin_type === 2) {
        // 全仓 -- 初始保证金
        temp.isolated_margin = Decimal.mul(temp.tempMarkPrice, temp.absSize).div(temp.leverage)
      } else {
        temp.lowestMargin = Decimal.mul(temp.tempMarkPrice, temp.absSize).div(temp.leverage)
      }

      // 股本回报率
      temp.calcRoe = Decimal.div(temp.calcPnl, temp.isolated_margin).toString()

      if (!type) {
        // 合约账户页面不需要计算强平价格
        // 强平价格
        if (!coinIdArr.includes(temp.coin_id)) {
          coinIdArr.push(temp.coin_id)
          // 计算非本合约的维持保证金和未实现盈亏
          computeMaintenanceMarginAPNL(data, temp.coin_id)
          if (!result[temp.coin_id]) {
            result[temp.coin_id] = handleAssignment(
              state.baseIdPositionsMap[temp.coin_id],
              assetsInfo
            )
          }
        }
        // 全仓 -- 强平价格
        temp.liquidationPrice = computeLiquidationPrice(temp, result[temp.coin_id])
        // console.log(temp.liquidationPrice)
      }

      Decimal.set({ rounding: Decimal.ROUND_UP })

      temp.positionMargin = temp.isolated_margin
        ? Decimal(temp.isolated_margin).toFixed(2)
        : '0.00'
      const tempPnl = thousandths(getNum(temp.calcPnl, 2))
      temp.positionPnl = temp.calcPnl > 0 ? `+${tempPnl}` : tempPnl
      temp.roeRate =
        temp.calcRoe > 0
          ? `+${getNum(Decimal.mul(temp.calcRoe, 100).toNumber(), 2)}%`
          : `${getNum(Decimal.mul(temp.calcRoe, 100).toNumber(), 2)}%`
      temp.markPrice = temp.tempMarkPrice
      temp.liquidationPrice =
        temp.liquidationPrice && Number(temp.liquidationPrice) > 0 ? getNum(temp.liquidationPrice, temp.pricedigit) : '--'

      if (state.amountUnit.value === 2) {
        temp.positionAmt = Decimal.mul(temp.absSize, temp.markPrice).toNumber()
        temp.positionAmt = `${temp.positionAmt.toFixed(temp.pricedigit)} USDT`
      } else {
        temp.positionAmt = `${getNum(temp.absSize, temp.amountdigit)} ${temp.coin}`
      }
      return temp
    })
  }
  return []
}

// 处理持仓列表初始数据
const handleInitData = (data) => {
  if (data && data.length) {
    return data.map((item) => {
      const temp = { ...item }
      // 持仓数量绝对值
      temp.absSize = Decimal.abs(temp.position_amt).toNumber()
      temp.inputQuantity = getNum(temp.absSize, item.amountdigit)
      temp.inputPrice = item.mark_price
      temp.calcQuantity = temp.inputQuantity
      temp.tempMarkPrice = item.mark_price
      temp.markPrice = temp.tempMarkPrice
      temp.liquidation_price =
        item.liquidation_price && Number(item.liquidation_price) < 0
          ? '--'
          : item.liquidation_price
      temp.liquidationPrice = temp.liquidation_price
      temp.calcPnl = item.unrealized_profit
      temp.calcRoe = item.roe_rate.replace('%', '')

      temp.isEnter = false
      temp.tableInputError = false
      if (state.amountUnit.value === 2) {
        temp.positionAmt = Decimal.mul(temp.absSize, item.mark_price).toNumber()
        temp.positionAmt = `${temp.positionAmt.toFixed(item.pricedigit)} USDT`
      } else {
        temp.positionAmt = `${getNum(temp.absSize, item.amountdigit)} ${item.coin}`
      }
      // 仓位初始保证金
      if (temp.margin_type === 2) {
        // 全仓 -- 初始保证金
        temp.positionMargin = Decimal.mul(temp.tempMarkPrice, temp.absSize)
          .div(temp.leverage)
          .toNumber()
          .toFixed(2)
      } else {
        temp.positionMargin = temp.isolated_margin
          ? Decimal(temp.isolated_margin).toFixed(2)
          : '0.00'
        temp.lowestMargin = Decimal.mul(temp.tempMarkPrice, temp.absSize)
          .div(temp.leverage)
          .toString()
      }
      // 订单方向
      temp.orderDirection =
        temp.position_side !== 'both'
          ? temp.position_side === 'short'
            ? -1
            : 1
          : temp.position_amt >= 0
            ? 1
            : -1
      // 持仓模式
      temp.positionMode = {
        isLong: temp.orderDirection == 1 && temp.position_side !== 'both', // 双向持仓 --- 开多
        isShort: temp.orderDirection == -1 && temp.position_side !== 'both', // 双向持仓 ---  开空
        isSingle: temp.position_side === 'both', // 单向持仓
        isBuy: temp.position_side === 'both' && temp.position_amt > 0, // 单向持仓 --- 买
        isSell: temp.position_side === 'both' && temp.position_amt < 0 // 单向持仓 --- 卖
      }
      if (!state.closeSize.hasOwnProperty(item.coin)) {
        state.closeSize[item.coin] = {
          long: '',
          short: ''
        }
      }
      if (item.position_side === 'short') {
        state.closeSize[item.coin].long = temp.absSize
      }
      if (item.position_side === 'long') {
        state.closeSize[item.coin].short = temp.absSize
      }
      return temp
    })
  } else {
    return []
  }
}

// 切换杠杆更新到持仓列表
const handleLeverage = ({ list, data }) => {
  if (list && list.length > 0) {
    return list.map((item) => {
      if (item.coin === data.coin) {
        item.leverage = data.leverage
      }
      return item
    })
  }
  return list
}

// 切换数量单位更新持仓列表
const handleAmounUnit = (list) => {
  if (list && list.length > 0) {
    return list.map((item) => {
      if (state.amountUnit.value === 2) {
        item.positionAmt = Decimal.mul(item.absSize, item.markPrice).toNumber()
        item.positionAmt = `${item.positionAmt.toFixed(item.pricedigit)} USDT`
      } else {
        item.positionAmt = `${getNum(item.absSize, item.amountdigit)} ${item.coin}`
      }
      return item
    })
  }
  return list
}

// 切换盘口价格更新持仓列表
const handleCapPrice = ({ list, data }) => {
  if (list && list.length > 0) {
    return list.map((item) => {
      if (item.coin === data.coin) {
        item.inputPrice = data.price
      }
      return item
    })
  }
  return list
}

// 计算合约资产相关
const handleAssetsData = (assetsInfo) => {
  const temp = {
    amount: getNum(assetsInfo.balance, 2), // 余额
    pnl: getNum(assetsInfo.unprofit, 2), // 未实现盈亏
    maintain: '0.00', // 维持保证金
    margin: getNum(assetsInfo.bond, 2), // 保证金余额
    marginRate: '0.00', // 保证金率
    usableMargin: getNum(assetsInfo.bond, 2) // 可用保证金
  }
  const list = state.futPositonList
  if (list && list.length) {
    let maintain = 0
    let pnl = 0
    let initMargin = 0
    const marginType = list[0].margin_type
    list.forEach((item) => {
      const tempPnl = item.calcPnl || 0
      pnl = Decimal.add(tempPnl, pnl).toNumber() // 所有仓位未实现盈亏之和

      const tempMargin = item.isolated_margin || 0
      initMargin = Decimal.add(tempMargin, initMargin).toNumber() // 所有仓位初始保证金之和

      const tempMaintain = item.maintain || 0
      maintain = Decimal.add(tempMaintain, maintain).toNumber() // 所有仓位维持保证金之和
    })
    temp.amount = getNum(assetsInfo.balance, 2)
    temp.margin =
      assetsInfo.balance && pnl ? Decimal.add(assetsInfo.balance, pnl).toNumber() : '0.00'
    temp.orgMargin = temp.margin
    temp.orgPnl = pnl

    temp.pnl = pnl ? getNum(pnl, 2) : '0.00'
    temp.maintain = maintain ? getNum(maintain, 2) : '0.00'
    temp.marginRate = getNum(Decimal.div(maintain, temp.margin).mul(100).toNumber(), 2)
    temp.margin = getNum(temp.margin, 2)
    if (marginType === 2) {
      // 全仓---可用下单余额
      temp.usableMargin = Decimal.max(
        0,
        Decimal.add(assetsInfo.balance, pnl).sub(initMargin)
      ).toNumber()
    } else {
      // 逐仓---可用下单余额
      temp.usableMargin = Decimal.max(0, Decimal.sub(assetsInfo.balance, initMargin)).toNumber()
    }
  }
  return temp
}

// 订单列表
const handleTableList = (data) => {
  if (data && data.length > 0) {
    return data.map((item) => {
      const temp = { ...item }
      temp.create_time = item.create_time
        ? getDate(Number(`${item.create_time.toString()}`), 'YYYY-MM-DD HH:mm:ss')
        : ''
      temp.update_time = item.update_time
        ? getDate(Number(`${item.update_time.toString()}`), 'YYYY-MM-DD HH:mm:ss')
        : ''
      if (item.buy_type === 1) {
        // 买入
        temp.orderDirectionText =
          temp.position_side === 'both'
            ? item.position_type
            : `${i18n.t('message.futures.buy')}${item.position_type}`
      }
      if (item.buy_type === 2) {
        // 卖出
        temp.orderDirectionText =
          temp.position_side === 'both'
            ? item.position_type
            : `${i18n.t('message.futures.sell')}${item.position_type}`
      }
      return temp
    })
  }
  return []
}
// 订单详情
const handleOrderInfo = (data) => {
  if (Object.keys(data).length > 0) {
    const temp = { ...data }
    if (temp.deal_list && temp.deal_list.length) {
      temp.deal_list = temp.deal_list.map((item) => {
        const tempItem = { ...item }
        tempItem.coin = data.coin
        tempItem.create_date = getDate(
          Number(`${item.create_date.toString()}`),
          'YYYY-MM-DD HH:mm:ss'
        )
        tempItem.amount = getNum(Decimal.mul(item.num, item.price).toNumber(), 8)
        tempItem.currency = data.currency
        tempItem.poundage = `${getNum(item.poundage, 8)} USDT`
        return tempItem
      })
    } else {
      temp.deal_list = []
    }
    return temp
  }
}

const getDefaultState = () => {
  return {
    baseId: null,
    futPositonList: [],
    baseIdPositionsMap: {},
    tradeOrderList: {
      list: [],
      total: 0
    },
    historyOrderList: {
      list: [],
      total: 0
    },
    recordOrderList: {
      list: [],
      total: 0
    },
    assetsOrderList: {
      list: [],
      total: 0
    },
    pendingOrder: 0, // 挂单数量
    orderInfo: {},
    listCount: {
      position: 0,
      entrust: 0
    },
    assetsData: {
      amount: '0.00', // 余额
      pnl: '0.00', // 未实现盈亏
      maintain: '0.00', // 维持保证金
      margin: '0.00', // 保证金余额
      marginRate: '0.00', // 保证金率
      usableMargin: '0.00' // 可用保证金
    },
    markPrice: {},
    newPrice: null,
    amountUnit: getFutUnit() ? JSON.parse(getFutUnit()) : { label: '', value: 1 },
    capPrice: null,
    closeSize: {},
    liquidationPrice: '',
    currentStandType: 0
  }
}
const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  // 初始化持仓列表
  setFutPList(state, data) {
    state.closeSize = {}
    state.futPositonList = handleInitData(data)
  },
  // 根据推送数据更新持仓列表
  updateFutList(state, data) {
    const assetsInfo = store.state.futuresTrade.assetInfo
    const tempList = handleList(data)
    state.futPositonList = handleFutList(tempList, assetsInfo, data.type)
    state.assetsData = handleAssetsData(assetsInfo)
    // state.listCount.position = state.futPositonList.length
  },
  // 逐仓根据保证金计算强平价
  calcLiquidationPrice(state, { data, assetInfo }) {
    computeMaintenanceMarginAPNL(state.futPositonList, data.coin_id)
    const result = handleAssignment(state.baseIdPositionsMap[data.coin_id], assetInfo)
    state.liquidationPrice = computeLiquidationPrice(data, result)
  },
  // 更新资产信息
  updateAssetsData(state, assetsInfo) {
    state.assetsData = handleAssetsData(assetsInfo)
  },
  // 更新杠杆倍数
  updateLeverage(state, data) {
    state.futPositonList = handleLeverage({ list: state.futPositonList, data })
  },
  // 更新数量单位
  updateUsdtPrice(state) {
    state.futPositonList = handleAmounUnit(state.futPositonList)
  },
  // 更新标记价
  updateMarkPrice(state, data) {
    state.markPrice = data
  },
  // 更新最新价
  updateNewPrice(state, data) {
    state.newPrice = data
  },
  // 填充盘口价格
  updateCapPrice(state, data) {
    state.capPrice = data.price
    state.futPositonList = handleCapPrice({ list: state.futPositonList, data })
  },
  // 更新数量单位
  updateAmountUnit(state, data) {
    state.amountUnit = data
    setFutUnit(JSON.stringify(data))
  },
  setOrderList(state, { type, data }) {
    // state.tradeOrderList.list = handleTableList(data.data_list)
    // state.tradeOrderList.total = data.data_total
    if (type === 1) {
      state.pendingOrder = data.data_total
      state.tradeOrderList.list = handleTableList(data.data_list)
      state.tradeOrderList.total = data.data_total
    } else if (type === 2) {
      state.historyOrderList.list = handleTableList(data.data_list)
      state.historyOrderList.total = data.data_total
    } else if (type === 3) {
      state.recordOrderList.list = handleTableList(data.data_list)
      state.recordOrderList.total = data.data_total
    } else if (type === 4) {
      state.assetsOrderList.list = handleTableList(data.data_list)
      state.assetsOrderList.total = data.data_total
    }
  },
  setOrderInfo(state, data) {
    state.orderInfo = handleOrderInfo(data)
  },
  setCurrentStandType(state, data) {
    state.currentStandType = data
  }
}

const actions = {
  // 合约持有仓位列表
  async getFutPList({ commit }, params) {
    try {
      const res = await Order.getFutPListApi(params)
      commit('setFutPList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取订单列表
  async getOrderList({ state, commit }, params) {
    try {
      state.tradeOrderList = {
        list: [],
        total: 0
      }
      const res = await Order.getOrderListApi(params, params.standType || 1)
      commit('setOrderList', { type: params.type, data: res.data })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取订单详情
  async getOrderInfo({ commit }, data) {
    try {
      const res = await Order.getOrderInfoApi(data)
      commit('setOrderInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 撤单
  async cancelOrder({ commit }, data) {
    try {
      await Order.cancelOrderApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 币本位 撤单
  async cancelBNotUOrder({ commit }, data) {
    try {
      await Order.cancelBNotUOrder(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 批量撤单
  async cancelbatchorders() {
    try {
      await Order.cancelbatchordersApi()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 币本位批量撤单
  async cancelbatchNotUorders() {
    try {
      await Order.cancelbatchNotUorders()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 调整保证金
  async adjustMargin(no, params) {
    try {
      await Order.adjustMarginApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 一键平仓
  async closeAll() {
    try {
      await Order.closeAllApi()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
