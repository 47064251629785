import request from '@/utils/request'

// 收款账号管理
export default {
  // 根据法币和交易方式获取输入项
  getTradeOptionsList({ legal_id, trade_id }) {
    return request({
      url: `/pay/input/item/${legal_id}/${trade_id}`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 收款账号 - 列表
  getAccountList() {
    return request({
      url: '/pay/trade/account/list',
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 收款账号 - 新增
  addAccount(data) {
    return request({
      url: '/pay/trade/account/add',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 收款账号 - 修改
  updateAccount(data) {
    return request({
      url: '/pay/trade/account/edit',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 收款账号 - 详情
  detailAccount(id) {
    return request({
      url: `/pay/trade/account/detail/${id}`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 收款账号 - 删除
  deleteAccount(data) {
    return request({
      url: '/pay/trade/account/del',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 上传文件 (二维码上传)
  uplodFile(data) {
    return request({
      url: '/upload/file',
      method: 'post',
      data,
      content_type: 'form',
      headers: { model_type: 'OTC' }
    })
  }
}
