import apiKey from '@/api/apiKey'

const state = {
  keyList: []
}

const mutations = {
  setkeyList(state, data) {
    state.keyList = data
  }
}

const actions = {
  // api列表
  async userkeyList({ commit }, data) {
    try {
      const res = await apiKey.userkeyList(data)
      commit('setkeyList', res.data)
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 新增api
  async userkeyAdd(payload, data) {
    try {
      const res = await apiKey.userkeyAdd(data)
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 验证秘钥
  async userkeyCaptcha(payload, data) {
    try {
      const res = await apiKey.userkeyCaptcha(data)
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 删除
  async userkeyDel(payload, data) {
    try {
      await apiKey.userkeyDel(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 删除所有
  async userkeyDelall(payload, data) {
    try {
      await apiKey.userkeyDelall(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 编辑
  async userkeyEdit(payload, data) {
    try {
      await apiKey.userkeyEdit(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
