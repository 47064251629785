import transaction from '@/api/spot/transaction'

const state = {
  newdeal_list: [],
  five_list: {
    ask: [],
    bid: []
  },
  kline_list: [],
  tickers: {},
  symbolasset: {},
  ticker: {},
  tradeOrder: {},
  dayTickerData: {},
  localIsStandard: localStorage.getItem('isStandard') || 1
}

const mutations = {
  getNewdealList(state, data) {
    // state.newdeal_list = data
    if (data && data.length > 0) {
      data.forEach(item => {
        state.newdeal_list.push([item.p, item.q, item.t, item.m])
      })
    }
  },
  getFivelist(state, data) {
    state.five_list.bid = data.bid
    state.five_list.ask = data.ask
  },
  getTickers(state, data) {
    state.tickers = data
  },
  getKlinelist(state, data) {
    // state.kline_list = data
    if (data && data.length > 0) {
      data.forEach(item => {
        state.kline_list.push([item.t, item.o, item.h, item.l, item.c, item.v])
      })
    }
  },
  getsymbolasset(state, data) {
    state.symbolasset = data
  },
  getTicker(state, data) {
    state.ticker = data
  },
  tradeOrder(state, data) {
    state.tradeOrder = data
  },
  getTickerDay(state, data) {
    state.dayTickerData = data
  },
  setIsStandard(state, data) {
    state.localIsStandard = data
    localStorage.setItem('isStandard', data)
  },
  resetKlineData(state) {
    state.kline_list = []
  }
}

const actions = {
  async getNewdealList({ state, commit }, data) {
    try {
      state.newdeal_list = []
      const res = await transaction.getNewdealListApi(data)
      commit('getNewdealList', res.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getFivelist({ commit }, data) {
    try {
      const res = await transaction.getFivelistApi(data)
      commit('getFivelist', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getTickers({ commit }, data) {
    try {
      const res = await transaction.getTickersApi(data)
      commit('getTickers', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getKlinelist({ commit }, data) {
    try {
      commit('resetKlineData')
      const res = await transaction.getKlinelistApi(data)
      commit('getKlinelist', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getsymbolasset({ commit }, data) {
    try {
      const res = await transaction.getsymbolassetApi(data)
      // console.log('res', res)
      commit('getsymbolasset', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getTicker({ commit }, data) {
    try {
      const res = await transaction.getTickerApi(data)
      commit('getTicker', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async tradeOrder({ commit }, data) {
    try {
      const res = await transaction.tradeOrderApi(data)
      commit('tradeOrder', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async cancelAllOrder(no, data) {
    try {
      await transaction.cancelAllOrderApi(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getTickerDay({ commit }, data) {
    try {
      const res = await transaction.getTickerDayApi(data)
      commit('getTickerDay', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
