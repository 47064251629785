import router from './router'
import { getToken } from './utils/auth'

const hiddenChat = (to) => {
  const el = document.getElementById('chat-widget-container')
  if (!['Home', 'Help', 'ArticleList', 'ArticleDetail', 'Search'].includes(to.name)) {
    if (el) {
      el.style.display = 'none'
    }
  } else {
    if (el) {
      el.style.display = 'block'
    }
  }
}

const blackList = ['/referral']

router.beforeEach((to, from, next) => {
  hiddenChat(to)
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (getToken() && ['/login', '/register'].includes(to.path)) {
    next({ path: '/' })
  } else if (!getToken() && blackList.includes(to.path)) {
    next({ path: '/login' })
  } else {
    next()
  }
})

// 现货交易页面币对切换后点击现货交易切换时处理
router.afterEach((to, from) => {
  if (from.name === 'Transaction' && to.name === 'Transaction' && to.params.other !== 1) {
    router.go(0)
  }
  hiddenChat(to)
})
