import Quotes from '@/api/quotes'
import store from '../index'
import { handleRatio, getNum, thousandths, simplifyMoney } from '@/utils'
import global from '@/utils/global'
import Decimal from 'decimal.js'

// const handleRatio = data => {
//   let tempNumArr = []
//   let tempNum = null
//   if (data.indexOf('-') > -1) tempNumArr = data.split('-')
//   tempNum = tempNumArr.length > 1 ? tempNumArr[1] : data
//   const tempRatio = math.divide(math.multiply(tempNum, 100), 100).toFixed(2)
//   const tempRatioStr = data.indexOf('-') > -1 ? '-' : '+'
//   return `${tempRatioStr}${tempRatio}%`
// }
const handleData = (data) => {
  const tempCompareList = {}
  const list = data.isLike ? data.list : data
  if (list && list.length > 0) {
    const rate_data = store.state.user.rate_data
    const result = []
    let idx = 0
    list.forEach((item) => {
      if (state.currency && state.currency != item.currency) {
        return
      }
      if (data.isLike && item.user_like == 0) {
        return
      }
      const tempObj = { ...item }
      const tempP = item.rate_price || item.new_price
      const tempPrice =
        Object.keys(rate_data).length > 0 ? Decimal.mul(tempP, rate_data.rate).toNumber() : ''
      const digit = tempPrice && tempPrice > 1 ? 2 : 6
      const price = tempPrice || tempPrice === 0 ? thousandths(getNum(tempPrice, digit)) : ''
      tempObj.convert_price =
        Object.keys(rate_data).length > 0 && price ? `${rate_data.ratesymbol} ${price}` : ''
      tempObj.ratio_str = handleRatio(item.ratio)
      tempObj.newPrice = thousandths(getNum(item.new_price, item.price_digit))
      tempObj.highPrice = thousandths(getNum(item.high_price, item.price_digit))
      tempObj.lowPrice = thousandths(getNum(item.low_price, item.price_digit))
      // tempObj.dealAmt = item.deal_num ? thousandths(getNum(item.deal_num, 2)) : thousandths(getNum(item.deal_amt, 2))
      tempObj.dealAmt = item.deal_amt ? simplifyMoney(getNum(item.deal_amt, 2)) : '--'
      const tradePair = item.currency ? `${item.coin}-${item.currency}` : `${item.coin}-${item.fut_type}`
      tempCompareList[tradePair] = idx
      idx++
      result.push(tempObj)
    })
    return { result, tempCompareList }
  }
  return { result: [], tempCompareList }
}

const state = {
  table_list: {
    list: [],
    total: 0
  },
  currency_group: {
    plate: [],
    tradeareaareaname: []
  },
  compare_list: {},
  searchText: '',
  currency: ''
}

const mutations = {
  setCurrency(state, data) {
    state.currency = data
  },
  setSearchText(state, data) {
    state.searchText = data
  },
  resetSearchText(state) {
    state.searchText = ''
  },
  setCurrencyGroup(state, data) {
    state.currency_group = data
    state.currency_group.plate.unshift('全部')
    // state.currency_group = {
    //   plate: ['innovate', 'meta', 'defi', 'nft', 'new'],
    //   tradeareaareaname: ['IOTA', 'FAME', 'PEOPLE', 'DOT', '1INCH', 'ANT']
    // }
  },
  setTableList(state, data) {
    const tempData = (data && data.data_list) || data
    const { result, tempCompareList } = handleData(tempData)
    state.table_list.list = result
    state.table_list.total = (data && data.data_total) || result.length
    state.compare_list = tempCompareList
  },
  resetData(state) {
    state.table_list.list = []
    state.table_list.total = 0
    state.currency = ''
    state.currency_group = {
      plate: [],
      tradeareaareaname: []
    }
  },
  resetTableData(state) {
    state.table_list.list = []
    state.table_list.total = 0
  },
  resetTableList(state) {
    state.table_list.list = state.table_list.list.filter((item) => item.user_like)
  },
  updateTablelist(state, data) {
    const rate_data = store.state.user.rate_data
    if (state.table_list.list.length > 0) {
      Object.keys(data).forEach((key) => {
        if (state.compare_list.hasOwnProperty(key)) {
          const temp = state.table_list.list[state.compare_list[key]]
          const tempData = data[key]
          if (temp) {
            // console.log(tempData)
            const oldP = Number(temp.new_price)
            const newP = Number(tempData[3])
            temp.color =
              newP > oldP
                ? global.basicSuccessColor
                : newP < oldP
                  ? global.basicErrorColor
                  : '#FFFFFF'
            temp.deal_num = tempData[2]
            temp.dealAmt = simplifyMoney(getNum(tempData[5], 2))
            const newPrice = getNum(tempData[3], temp.price_digit)
            temp.new_price = newPrice
            temp.newPrice = thousandths(newPrice)
            temp.ratio = tempData[4]
            temp.ratio_str = handleRatio(tempData[4])
            temp.highPrice = thousandths(getNum(tempData[6], temp.price_digit))
            temp.lowPrice = thousandths(getNum(tempData[7], temp.price_digit))
            const tempPrice =
              Object.keys(rate_data).length > 0
                ? Decimal.mul(tempData[8], rate_data.rate).toNumber()
                : ''
            const digit = tempPrice && tempPrice > 1 ? 2 : 6
            const price = tempPrice ? thousandths(getNum(tempPrice, digit)) : ''
            temp.convert_price =
              Object.keys(rate_data).length > 0 ? `${rate_data.ratesymbol} ${price}` : ''
            state.table_list.list[state.compare_list[tempData[0]]] = temp
          }
        }
      })
    }
  },
  updateFutTableList(state, data) {
    // 避免以后的数据错乱
    const fut_type = data.fut_type || 0
    data = data.data || data
    const rate_data = store.state.user.rate_data
    if (state.table_list.list.length > 0) {
      Object.keys(data).forEach((key) => {
        const tempKey = `${key}-${fut_type}`
        if (state.compare_list.hasOwnProperty(tempKey)) {
          const temp = state.table_list.list[state.compare_list[tempKey]]
          const tempData = data[key]
          // console.log(temp, tempData)
          if (temp && fut_type && fut_type == temp.fut_type) {
            const oldP = Number(temp.new_price)
            const newP = Number(tempData[1])
            temp.color =
              newP > oldP
                ? global.basicSuccessColor
                : newP < oldP
                  ? global.basicErrorColor
                  : '#FFFFFF'
            temp.deal_amt = tempData[0]
            temp.dealAmt = simplifyMoney(getNum(tempData[0], 2))
            const newPrice = getNum(tempData[1], temp.price_digit)
            temp.new_price = newPrice
            temp.newPrice = thousandths(newPrice)
            temp.ratio_str = handleRatio(tempData[4])
            temp.high_price = tempData[3]
            temp.highPrice = thousandths(getNum(tempData[3], temp.price_digit))
            temp.low_price = tempData[2]
            temp.lowPrice = thousandths(getNum(tempData[2], temp.price_digit))
            const tempPrice =
              Object.keys(rate_data).length > 0
                ? Decimal.mul(newPrice, rate_data.rate).toNumber()
                : ''
            const digit = tempPrice && tempPrice > 1 ? 2 : 6
            const price = tempPrice ? thousandths(getNum(tempPrice, digit)) : ''
            temp.convert_price =
              Object.keys(rate_data).length > 0 ? `${rate_data.ratesymbol} ${price}` : ''
            state.table_list.list[state.compare_list[key]] = temp
          }
        }
      })
    }
  }
}

const actions = {
  async getAccountLike({ commit }) {
    try {
      const res = await Quotes.getAccountLikeApi()
      if (!res) return
      commit('setTableList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateLike(no, params) {
    try {
      await Quotes.updateLikeApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async addLike(no, params) {
    try {
      await Quotes.addLikeApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCurrencyGroup({ commit }) {
    try {
      const res = await Quotes.getCurrencyGroupApi()
      commit('setCurrencyGroup', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCurrencyTickers({ commit }, data) {
    try {
      const res = await Quotes.getCurrencyTickersApi(data)
      commit('setTableList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getTickers({ commit }, params) {
    try {
      const res = await Quotes.getTickersApi(params)
      commit('setTableList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
