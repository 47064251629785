import Footer from '@/api/footer'

const handleDataList = ({ data, type }) => {
  if (data.length > 0) {
    return data.map(item => {
      const temp = { ...item }
      if (type === 2) {
        temp.currency = 'USDT'
      }
      return temp
    })
  }
  return []
}

const handleAboutList = data => {
  if (data.length > 0) {
    let tempObj
    data.forEach(el => {
      if (el.title === '关于我们') {
        tempObj = { ...el }
      }
    })
    return tempObj
  }
  return {}
}

const handleList = data => {
  if (data && data.length > 0) {
    let tempArr = []
    data.forEach(item => {
      if (item.id === 2 && item.child && item.child.length > 0) {
        // 常见问题
        item.child.forEach(el => {
          if (el.id === 23) {
            // 充值
            tempArr = [...el.child]
          }
        })
      }
    })
    return tempArr
  }
  return []
}

const hsndleDepositWithdrawList = data => {
  if (data && data.length) {
    data.map(item => {
      if (item.rate && item.rate.length > 0) {
        item.rate.map((el, index) => {
          el.idx = index
          return el
        })
      }
      return item
    })
  }
  return data
}

const state = {
  tradeList: [],
  depositWithdrawList: [],
  articleList: [],
  articleDetail: {},
  articleSearch: [],
  aboutList: {},
  rechargeArticleList: []
}
const mutations = {
  setTradeList(state, { data, type }) {
    state.tradeList = handleDataList({ data, type })
  },
  setDepositWithdraw(state, data) {
    state.depositWithdrawList = hsndleDepositWithdrawList(data)
  },
  setArticleList(state, data) {
    state.articleList = data
    state.aboutList = handleAboutList(data)
    state.rechargeArticleList = handleList(data)
  },
  setArticleDetail(state, data) {
    state.articleDetail = data
  },
  setArticleSearch(state, data) {
    state.articleSearch = data
  }
}

const actions = {
  async getTradeList({ commit }, type) {
    try {
      const res = await Footer.getTradeListApi(type)
      commit('setTradeList', { data: res.data, type })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDepositWithdraw({ commit }) {
    try {
      const res = await Footer.getDepositWithdrawApi()
      commit('setDepositWithdraw', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDownloadUrl({ commit }) {
    try {
      const res = await Footer.getDownloadUrlApi()
      return res.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getArticleList({ commit }) {
    try {
      const res = await Footer.getArticleListApi()
      commit('setArticleList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getArticleDetail({ commit }, id) {
    try {
      const res = await Footer.getArticleDetailApi(id)
      commit('setArticleDetail', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getArticleSearch({ commit }, data) {
    try {
      const res = await Footer.getArticleSearchApi(data)
      commit('setArticleSearch', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
