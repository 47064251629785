import request from '@/utils/request'
export default {
  // 获取委托订单列表
  getEntrustListApi(data) {
    return request({
      url: `/trade/orders`,
      method: 'get',
      params: data
    })
  },
  // 获取历史成交列表
  getHistoryListApi(data) {
    return request({
      url: `/trade/GetHistoryOrders`,
      method: 'get',
      params: data
    })
  },
  // 获取单个订单信息
  getOrderInfoApi(order_no) {
    return request({
      url: `/trade/order?order_no=${order_no}`,
      method: 'get'
    })
  },
  // 撤单
  cancelOrderApi(data) {
    return request({
      url: `/trade/cancelorder`,
      method: 'post',
      data
    })
  }
}
