<template>
  <section class="app-main">
    <div class="line"></div>
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key"/>
    </keep-alive> -->
    <router-view v-if="!$route.meta.keepAlive" :key="key"/>
  </section>
</template>
<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>
<style lang="scss" scoped>
.app-main{
  min-height: 500px;
}
.line{
  width: 100%;
  height: 1px;
  background-color: #44454D;
}
</style>
