import BuyCoins from '@/api/buyCoins'
import User from '@/api/user'
const state = {
  is_business: true,
  coinsList: [],
  legalCoinsList: []
}

const mutations = {
  setCoinsList(state, data) {
    state.coinsList = data
  },
  setLegalcoins(state, data) {
    state.legalCoinsList = data
  }
}

const actions = {
  async getCoinsList({ commit }, type) {
    // 快捷区1 , 3 自选区
    try {
      const res = await BuyCoins.getCoinsType(type)
      const resLegal = await BuyCoins.getLegalcoins()
      commit('setCoinsList', res.data)
      commit('setLegalcoins', resLegal.data)
      return { coinsList: res.data, legalcoinsList: resLegal.data }
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 订单列表币种列表，法币列表
  async getOrderCoinsList({ commit }) {
    try {
      const res = await BuyCoins.getAllOtcCoins()
      const resLegal = await BuyCoins.getLegalcoins()
      const resData = {
        coinList: res.data,
        legalList: resLegal.data
      }
      return resData
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 购买验证 / 出售验证
  async checkOrderType({ commit }, data) {
    // console.log('data', data);
    try {
      // type 1 购买， 2出售
      let res = {}
      if (data.type === 1) {
        res = await BuyCoins.checkbuy(data.params)
      } else {
        res = await BuyCoins.checksell(data.params)
      }
      return res
    } catch (err) {
      return err
    }
  },

  // 快捷购买/快捷出售
  async quickOrder({ commit }, data) {
    try {
      // type 1 购买， 2出售
      let res = {}
      if (data.type === 1) {
        res = await BuyCoins.quickbuy(data.params)
      } else {
        res = await BuyCoins.quicksell(data.params)
      }
      return res
    } catch (err) {
      return err
    }
  },

  async getChangeNum({ commit }, data) {
    // console.log('data', data);
    try {
      // type 1 根据输入的金额获取可获取XXX数量的交易币 2根据输入的交易币数量计算可获取XXX法币
      let res = {}
      if (data.type === 1) {
        res = await BuyCoins.getBuyexChangeCoin(data.params)
      } else {
        res = await BuyCoins.getSellexChangeAmount(data.params)
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 常见问题
  async getProblemList() {
    try {
      const res = await BuyCoins.getProblemList()
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 认证验证
  async checkKycmobile() {
    try {
      const res = await BuyCoins.checkKycmobile()
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 是否为商家
  async checkIsBusiness({ commit }) {
    try {
      const res = await User.getUserInfoApi()
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 快捷区 获取付款方式/ 收款方式
  async fastPayMethod({ commit }, data) {
    try {
      // type 1 购买， 2出售
      let res = {}
      if (data.type === 1) {
        res = await BuyCoins.selectPayAmount(data.params)
      } else {
        res = await BuyCoins.selectRecPayAccount(data.params)
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  //  收款方式(自选广告出售使用)
  async selectRecPayaccountSale({ commit }, data) {
    try {
      const res = await BuyCoins.selectRecPayaccountSale(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 获取交易币汇率
  async getCoinLegalRate({ commit }, { coinid, legalid }) {
    try {
      const res = await BuyCoins.getCoinLegalRate(coinid, legalid)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 购买/出售下单
  async buyOrSellOrder({ commit }, data) {
    try {
      // type 1 购买， 2出售
      let res = {}
      if (data.type === 1) {
        res = await BuyCoins.buyorder(data.params)
      } else {
        res = await BuyCoins.sellorder(data.params)
      }
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 订单列表
  async getOrderList({ commit }, data) {
    try {
      const res = await BuyCoins.getOrderList(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 对手信息
  async getSideUserInfo({ commit }, data) {
    try {
      const res = await BuyCoins.getSideUserInfo(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 交易方式 - 选项, 自选区搜索
  async custormPayOptions({ commit }, data) {
    try {
      const res = await BuyCoins.selectPayAmount(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  //  自选区广告列表
  async selfAdvertiseList({ commit }, data) {
    try {
      let res = {}
      if (data.token) {
        res = await BuyCoins.selfAdvertiseList2(data.params)
      } else {
        res = await BuyCoins.selfAdvertiseList(data.params)
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 购买验证(自选区)
  async customCheckbuy({ commit }, data) {
    try {
      const res = await BuyCoins.customCheckbuy(data)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  //  出售验证(自选区)
  async customChecksell({ commit }, data) {
    try {
      const res = await BuyCoins.customChecksell(data)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  },

  //  获取币种可用余额
  async getcoinBalance({ commit }, data) {
    try {
      const res = await BuyCoins.getcoinBalance(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },
  // 获取导出订单数据
  async getOrderlistExport({ commit }, data) {
    try {
      const res = await BuyCoins.getOrderlistExport(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },
  // 新手引导
  async noviceGuide({ commit }) {
    try {
      const res = await BuyCoins.noviceGuide()
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
