import PayMethoManage from '@/api/payMethoManage'
import BuyCoins from '@/api/buyCoins'
import Bussiness from '@/api/bussiness'
const state = {}

const mutations = {}

const actions = {
  // 根据法币和交易方式获取输入项
  async getTradeOptionsList({ commit }, data) {
    try {
      const res = await PayMethoManage.getTradeOptionsList(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 收款账号 - 列表
  async getAccountList({ commit }) {
    try {
      const res = await PayMethoManage.getAccountList()
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  //  获取法币列表
  async getLegalCoinList({ commit }) {
    try {
      const res = await BuyCoins.getLegalcoins()
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 根据法币获取付款方式
  async getAdPayAmount({ commit }, data) {
    try {
      const res = await Bussiness.selectPayAmount(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 新增， 删除， 查看， 编辑
  async handleAccountAction({ commit }, data) {
    try {
      let res = {}
      switch (data.type) {
        case 'add':
          res = await PayMethoManage.addAccount(data.params)
          break
        case 'update':
          res = await PayMethoManage.updateAccount(data.params)
          break
        case 'detail':
          res = await PayMethoManage.detailAccount(data.params)
          break
        case 'del':
          res = await PayMethoManage.deleteAccount(data.params)
          break
        default:
          break
      }
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  // 上传文件 (二维码上传)
  async uplodFile({ commit }, data) {
    try {
      const res = await PayMethoManage.uplodFile(data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
