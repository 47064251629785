<template>
  <div>
    <Headers />
    <app-main ref="appMain" />
    <Footer />
  </div>
</template>
<script>
import { Headers, AppMain, Footer } from './components'
export default {
  name: 'LayoutBox',
  data() {
    return {
    }
  },
  components: {
    Headers,
    AppMain,
    Footer
  }
}
</script>
