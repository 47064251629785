<template>
 <div style="height: 100%;" class="flex flex-direction">
    <Headers />
    <div class="flex-1 bg-c0B0E11 flex justify-center align-center">
      <div class="flex justify-center flex-direction">
        <div class="bg-white b-r-8 p-40 w-510">
          <router-view></router-view>
        </div>
        <div class="m-t-21 relative" v-if="routeName === 'Login'">
          <img class="w-510 h-134" src="@/assets/user/download_icon.png" alt="">
          <vue-qr class="qr_code" :text="downloadQrcode" :size="85" :margin="2" colorDark="#000"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Headers } from '@/layout/components'
import VueQr from 'vue-qr'
export default {
  components: {
    Headers,
    VueQr
  },
  data() {
    return {
      routeName: this.$route.name,
      downloadQrcode: ''
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.routeName = route.name
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.qr_code{
  border-radius: 4px;
  position: absolute;
  top: 25px;
  right: 22px;
}
</style>
