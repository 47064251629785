import request from '@/utils/request'
export default {
  // 获取邀请码列表
  getInviteCodeListApi() {
    return request({
      url: '/invite/list',
      method: 'get'
    })
  },
  // 添加邀请码
  addInviteCodeApi(data) {
    return request({
      url: '/invite/add',
      method: 'post',
      data
    })
  },
  // 修改邀请码备注
  modifyCodeMarkApi(data) {
    return request({
      url: '/invite/changeremark',
      method: 'post',
      data
    })
  },
  // 返现返佣记录
  getCommissionApi(data) {
    return request({
      url: `/invite/commissionLog?CommisType=${data.CommisType}&time_info=${data.time_info}&page=${data.page}&page_size=${data.page_size}&start_time=${data.start_time}&end_time=${data.end_time}`,
      method: 'get'
    })
  },
  // 邀请用户记录
  getInviteUserListApi(data) {
    return request({
      url: `/invite/inviteuserlist`,
      method: 'get',
      params: { ...data }
    })
  },
  // 统计
  getStatisticsApi() {
    return request({
      url: '/invite/statistics',
      method: 'get'
    })
  },
  // 获取下载邀请海报
  homeGetinviteimage(data) {
    return request({
      url: '/home/getinviteimage',
      method: 'post',
      data
    })
  }
}
