import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import user from './modules/user'
// import home from './modules/home'
// import assets from './modules/assets'
// import authenticate from './modules/authenticate'
// import quotes from './modules/quotes'
// import station from './modules/station'
// import invite from './modules/invite'
// import footer from './modules/footer'
// import buyCoin from './modules/buyCoin'
// import payMethoManage from './modules/payMethoManage'
// import bussiness from './modules/bussiness'
// import buyOrder from './modules/buyOrder'
// import help from './modules/help'
// import apiKey from './modules/apiKey'
// import transfer from './modules/transfer'
// import webSocketData from './modules/webSocketData'
import spotOrder from './modules/spot/order'
import transation from './modules/spot/transation'
import futuresTrade from './modules/futures/trade'
import futuresOrder from './modules/futures/order'
import deliveryTrade from './modules/delivery/trade'
import deliveryOrder from './modules/delivery/order'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules: {
    ...modules,
    ...{
      spotOrder,
      transation,
      futuresTrade,
      futuresOrder,
      deliveryTrade,
      deliveryOrder
    }
  },
  getters
})

export default store

// const store = new Vuex.Store({
//   modules: {
//     user,
//     home,
//     assets,
//     authenticate,
//     quotes,
//     station,
//     invite,
//     footer,
//     buyCoin,
//     payMethoManage,
//     bussiness,
//     buyOrder,
//     help,
//     apiKey,
//     webSocketData,
//     transfer,
//     spotOrder,
//     transation,
//     futuresTrade,
//     futuresOrder,
//     deliveryTrade,
//     deliveryOrder
//   },
//   getters
// })

// export default store
