<template>
  <footer class="footer_box">
    <div class="footer_content">
      <div class="left">
        <div v-for="item in footerModule" :key="item.value" class="item">
          <div class="title">{{ item.title }}</div>
          <span v-for="(el, index) in item.children" :key="el.title || index" class="el_link">
            <a v-if="el.url.length >32 " :href="el.url" target="_blank">{{ el.title }}</a>
            <router-link v-else-if="el.url" :to="el.url">{{ el.title }}</router-link>
            <span v-else @click="selectUrl(el)">{{ el.title }}</span>
          </span>
        </div>
      </div>
      <div class="right">
        <div class="community">TOKEX {{ $t('message.footer.community') }}</div>
        <div class="m-b-21 w-222">
          <router-link v-for="(item, index) in icons" :key="item.type"
            :style="{ 'margin-right': index === 5 ? 0 : '20px' }" class="item_icon" to="">
            <span @mouseenter="iconSelected = item.type" @mouseleave="iconSelected = null">
              <img class="icon" :src="iconSelected === item.type ? item.imgUrlY : item.imgUrl" alt="" />
            </span>
          </router-link>
        </div>
        <el-dropdown class="link" placement="bottom" @visible-change="visibleEnvironDropdown">
          <div>
            <span :style="{ 'margin-right': '49px' }">{{`${currentLanguage}/${currency}`}}</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <environ-dropdown ref="environDropdown" class="footer_environ_dropdown" :languageList="languageList"
            :currencyList="currencyList">
          </environ-dropdown>
        </el-dropdown>
      </div>
    </div>
    <div class="copyright">@2020-2021.TOKEX</div>
  </footer>
</template>
<script>
import EnvironDropdown from './environDropdown'
import { mapState } from 'vuex'
export default {
  name: 'FooterBox',
  components: { EnvironDropdown },
  data() {
    return {
      footerModule: [],
      icons: [
        {
          type: 'twitter',
          imgUrl: require('@/assets/common/twitter.png'),
          imgUrlY: require('@/assets/common/twitter_y.png')
        },
        {
          type: 'tg',
          imgUrl: require('@/assets/common/tg.png'),
          imgUrlY: require('@/assets/common/tg_y.png')
        },
        {
          type: 'utube',
          imgUrl: require('@/assets/common/utube.png'),
          imgUrlY: require('@/assets/common/utube_y.png')
        },
        {
          type: 'facebook',
          imgUrl: require('@/assets/common/facebook.png'),
          imgUrlY: require('@/assets/common/facebook_y.png')
        },
        {
          type: 'in',
          imgUrl: require('@/assets/common/in.png'),
          imgUrlY: require('@/assets/common/in_y.png')
        },
        {
          type: 'reddit',
          imgUrl: require('@/assets/common/reddit.png'),
          imgUrlY: require('@/assets/common/reddit_y.png')
        },
        {
          type: 'medium',
          imgUrl: require('@/assets/common/medium.png'),
          imgUrlY: require('@/assets/common/medium_y.png')
        },
        {
          type: 'discord',
          imgUrl: require('@/assets/common/discord.png'),
          imgUrlY: require('@/assets/common/discord_y.png')
        },
        {
          type: 'ins',
          imgUrl: require('@/assets/common/ins.png'),
          imgUrlY: require('@/assets/common/ins_y.png')
        }
      ],
      iconSelected: null
    }
  },
  computed: {
    ...mapState({
      language: state => state.user.language,
      currency: state => state.user.currency,
      articleList: state => state.footer.articleList,
      aboutList: state => state.footer.aboutList,
      currencyList: state => state.user.rate_list,
      languageList: state => state.user.languageList,
      langObj: state => state.user.langObj
    }),
    currentLanguage() {
      return this.langObj[this.language]
    }
  },
  created() {
    this.initModule()
    this.$store.dispatch('footer/getArticleList').then(() => {
      if (this.aboutList.child && this.aboutList.child.length > 0) {
        const child = this.footerModule[0].children
        this.aboutList.child.forEach((el, index) => {
          if (child[index]) {
            child[index].title = el.title
            child[index].url.query = { id: el.id }
          }
        })
      }
    })
  },
  methods: {
    initModule() {
      this.footerModule = [
        {
          title: this.$t('message.footer.aboutUs'),
          children: [
            {
              title: '',
              url: { name: 'AboutUs', params: { menuType: 'about' }}
            },
            {
              title: '',
              url: { name: 'AboutUs', params: { menuType: 'terms' }}
            },
            {
              title: '',
              url: { name: 'AboutUs', params: { menuType: 'privacy' }}
            },
            {
              title: '',
              url: { name: 'AboutUs', params: { menuType: 'state' }}
            }
          ],
          value: 0
        },
        {
          title: this.$t('message.footer.product'),
          children: [
            {
              title: this.$t('message.footer.spotDeal'),
              url: { name: 'Transaction', params: { tradePair: 'BTC_USDT' }}
            },
            {
              title: this.$t('message.footer.fiatDeal'),
              url: { name: 'BuyCustorm' }
            },
            {
              title: this.$t('message.footer.sustainable'),
              url: { name: 'FuturesTrade', params: { tradePair: 'BTCUSDT' }}
            },
            {
              title: this.$t('message.footer.wallet'),
              url: ''
            },
            {
              title: this.$t('message.footer.download'),
              url: '/download'
            },
            {
              title: this.$t('message.footer.derivatives'),
              url: ''
            }
          ],
          value: 1
        },
        {
          title: this.$t('message.footer.serve'),
          children: [
            {
              title: this.$t('message.footer.upCoin'),
              url: ''
            },
            {
              title: this.$t('message.footer.buyCoin'),
              url: { name: 'BuyFast' }
            },
            {
              title: this.$t('message.footer.rebate'),
              url: '/referral'
            },
            {
              title: this.$t('message.footer.bulk'),
              url: ''
            },
            {
              title: this.$t('message.footer.custom'),
              url: ''
            },
            {
              title: this.$t('message.footer.Affiliate'),
              url: 'http://47.243.128.29:6241/#/agentPage'
            }
          ],
          value: 2
        },
        {
          title: this.$t('message.footer.help'),
          children: [
            {
              title: this.$t('message.footer.help'),
              url: { name: 'Help' }
            },
            {
              title: this.$t('message.footer.service'),
              url: ''
            },
            {
              title: this.$t('message.footer.rate'),
              url: { name: 'TradeRate' }
            },
            {
              // api文档
              title: this.$t('message.footer.api'),
              url: ''
            },
            {
              title: this.$t('message.footer.suggest'),
              url: ''
            }
          ],
          value: 3
        }
      ]
    },
    visibleEnvironDropdown(visible) {
      console.log(visible)
      if (visible) {
        this.$store.dispatch('user/getRateList')
        this.languageList.forEach(el => {
          if (el.value === this.language) {
            this.$refs.environDropdown.currentLanguage = el.name
          }
        })
        this.currencyList.forEach((el, index) => {
          if (el.tocoin === this.currency) {
            this.$refs.environDropdown.currentCurrency = index
          }
        })
      }
    },
    selectUrl(el) {
      if (!el.url) {
        this.$message({
          message: this.$t('message.footer.develop'),
          duration: 1000
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$max-width: 1200px;

@mixin common-title() {
  font-size: 20px;
  font-family: PingFang_Medium;
  color: $basicWhite;
  margin-bottom: 20px;
  line-height: 23px;
}

.footer_box {
  padding-top: 40px;
  padding-bottom: 10px;
  background-color: #141515;

  .footer_content {
    display: flex;
    padding-bottom: 60px;
    max-width: $max-width;
    margin: 0 auto;
    justify-content: space-between;
  }

  .left {
    display: flex;
    justify-content: flex-start;

    .item {
      display: flex;
      flex-direction: column;
      margin-right: 100px;

      &:last-child {
        margin-right: 0;
      }

      .title {
        @include common-title();
      }

      .el_link {
        font-size: 14px;
        color: #848E9C;
        margin-bottom: 20px;
        line-height: 14px;

        &>span {
          font-family: PingFang_Regular;
        }

        &>a {
          font-family: PingFang_Regular;
        }

        &:hover {
          color: $basicColor;
          cursor: pointer;
        }
      }
    }
  }

  .right {
    .community {
      @include common-title();
    }

    .item_icon {
      margin-bottom: 20px;
      display: inline-block;
      font-size: 0;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .el-dropdown {
    width: 179px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #B7BDC6;
    line-height: 40px;
    text-align: center;
  }

  .copyright {
    border-top: 1px solid #2A2A36;
    text-align: center;
    padding-top: 10px;
    font-size: 12px;
    font-family: PingFang_Regular;
    color: #636B75;
  }
}
</style>
<style lang="scss">
.footer_environ_dropdown {

  // top: 3885px !important;
  .popper__arrow {
    display: none !important;
  }
}
</style>
