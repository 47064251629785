import request from '@/utils/request'

export default {
  // 加载交易币列表
  getCoinsType(type) {
    return request({
      url: '/trade/getcoins' + (type ? `/${type}` : ''),
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 获取法币列表
  getLegalcoins(data) {
    return request({
      url: '/trade/getLegalcoins',
      method: 'get',
      params: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 获取认证状态
  checkKycmobile() {
    return request({
      url: '/trade/getkycmobile',
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 快捷出售
  quicksell(data) {
    return request({
      url: '/trade/quicksell',
      method: 'post',
      data: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 快捷购买
  quickbuy(data) {
    return request({
      url: '/trade/quickbuy',
      method: 'post',
      data: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 购买验证, 自选区
  checkbuy(data) {
    return request({
      url: '/trade/checkbuy',
      method: 'post',
      data: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 出售验证, 自选区
  checksell(data) {
    return request({
      url: '/trade/checksell',
      method: 'post',
      data: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 根据输入的交易币数量计算可获取XXX法币
  getSellexChangeAmount(data) {
    return request({
      url: '/trade/getsellexchangeamount',
      method: 'post',
      data: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 根据输入的金额获取可获取XXX数量的交易币
  getBuyexChangeCoin(data) {
    return request({
      url: '/trade/getbuyexchangecoin',
      method: 'post',
      data: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 获取交易币汇率
  getCoinLegalRate(coinid, legalid) {
    return request({
      url: `/trade/getcoinlegalrate/${coinid}/${legalid}`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 常见问题
  getProblemList() {
    return request({
      url: '/article/list',
      method: 'get',
      params: { category_id: 2 }
    })
  },

  // 获取付款方式
  selectPayAmount(data) {
    return request({
      url: '/c2c/selectpayamount',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 收款方式(发布广告用,快捷支付用)
  selectRecPayAccount(data) {
    return request({
      url: '/c2c/selectrecpayaccount',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 收款方式(自选广告出售使用)
  selectRecPayaccountSale(data) {
    return request({
      url: '/c2c/selectrecpayaccountsale',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 购买下单
  buyorder(data) {
    return request({
      url: '/trade/buyorder',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 出售下单
  sellorder(data) {
    return request({
      url: '/trade/sellorder',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 订单列表
  getOrderList(data) {
    return request({
      url: '/trade/getorderlist',
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 获取所有币种列表(订单用)
  getAllOtcCoins() {
    return request({
      url: '/trade/getallotccoins',
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 获取对手信息
  getSideUserInfo(id) {
    return request({
      url: `/trade/sideuserinfo/${id}`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },

  // 交易方式 - 选项, 自选区搜索
  custormPayOptions(data) {
    return request({
      url: `/pay/trade/set/options`,
      method: 'get',
      params: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 自选区广告列表（未登录）
  selfAdvertiseList(data) {
    return request({
      url: `/c2c/selfadvertiselist`,
      method: 'get',
      params: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 自选区广告列表(登录)
  selfAdvertiseList2(data) {
    return request({
      url: `/c2c/selfadvertiselist2`,
      method: 'get',
      params: data,
      headers: { model_type: 'OTC' }
    })
  },

  // 购买验证(自选区)
  customCheckbuy(data) {
    return request({
      url: `/trade/checkbuy`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 出售验证(自选区)
  customChecksell(data) {
    return request({
      url: `/trade/checksell`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },

  // 获取币种可用余额
  getcoinBalance(data) {
    return request({
      url: `/trade/getcoinbalance/${data}`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  },
  // 获取导出订单数据
  getOrderlistExport(data) {
    return request({
      url: `/trade/getorderlistexport`,
      method: 'post',
      data,
      headers: { model_type: 'OTC' }
    })
  },
  // 新手引导
  noviceGuide() {
    return request({
      url: `/novice/guide`,
      method: 'get',
      headers: { model_type: 'OTC' }
    })
  }
}
