import request from '@/utils/request'
export default {
  // 合约持有仓位列表
  getFutPListApi(data) {
    return request({
      url: '/futures/getfutpositionslist',
      method: 'post',
      data
    })
  },
  // 币本位 合约持有仓位列表
  getFutPListNotUApi(data) {
    return request({
      url: '/asset/getfutpositionslist',
      method: 'get',
      data,
      headers: { model_type: 'DAPI' }
    })
  },
  // 获取订单列表
  getOrderListApi(params, standType = 1) {
    // standType 1 u本位，2 币本位
    // params.type: 1-当前委托  2-历史委托  3-成交记录  4-资金流水
    const tempParams = { ...params }
    const temp = {}
    let url
    let method = 'get'
    if (standType == 1) {
      const apiList = {
        1: '/futures/trade/orders',
        2: '/futures/trade/orders',
        3: '/futures/trade/deals',
        4: '/futures/capital/flow'
      }
      url = apiList[tempParams.type]
    } else {
      const apiList = {
        1: '/trade/orders',
        2: '/trade/orders',
        3: '/trade/deals',
        4: '/trade/capital/flow'
      }
      url = apiList[tempParams.type]
      temp.headers = { model_type: 'DAPI' }
      if (tempParams.type == 4) {
        method = 'post'
      }
    }

    delete tempParams.type
    // 去除合约类型参数
    delete tempParams.standType

    temp.url = url
    temp.method = method
    temp.params = tempParams
    return request(temp)
  },
  // 获取订单详情
  getOrderInfoApi(params) {
    return request({
      url: '/futures/trade/order',
      method: 'get',
      params
    })
  },
  // 撤单
  cancelOrderApi(data) {
    return request({
      url: '/futures/trade/cancelorder',
      method: 'post',
      data
    })
  },
  // 币本位撤单
  cancelBNotUOrder(data) {
    return request({
      url: '/trade/cancelorder',
      method: 'post',
      data,
      headers: { model_type: 'DAPI' }
    })
  },
  // 批量撤单
  cancelbatchordersApi() {
    return request({
      url: '/futures/trade/cancelbatchorders',
      method: 'post'
    })
  },
  cancelbatchNotUorders() {
    return request({
      url: '/trade/cancelbatchorders',
      method: 'post',
      headers: { model_type: 'DAPI' }
    })
  },
  // 调整逐仓保证金
  adjustMarginApi(data) {
    console.log(data)
    return request({
      url: '/futures/trade/adjustmargin',
      method: 'post',
      data
    })
  },
  // 一键平仓
  closeAllApi() {
    return request({
      url: '/futures/trade/closeall',
      method: 'post'
    })
  }
}
